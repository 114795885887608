import {
    MAP_PAGE,
    HOME_PAGE,
    INIT_PASSWORD_PAGE,
    LOGIN_PAGE,
    MATERIAL_DEMO_PAGE,
    REPORT_PAGE,
    RESET_PASSWORD_PAGE,
    RESULTAT_PAGE,
    SIMULATION_PAGE,
} from "../../constants/router";

export default {
    [HOME_PAGE]: '/',
    [MAP_PAGE]: '/map',
    [LOGIN_PAGE]: '/login',
    [RESET_PASSWORD_PAGE]: '/resetPassword/:resetToken',
    [INIT_PASSWORD_PAGE]: '/initPassword/:resetToken',
    [SIMULATION_PAGE]: '/simulation/:id/editing',
    [RESULTAT_PAGE]: '/simulation/:id/result',
    [MATERIAL_DEMO_PAGE]: '/theme-demo',
    [REPORT_PAGE]: '/simulation/:id/report',
}