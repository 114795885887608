import {STATIC_DATA_FETCH_FULFILLED} from "../../constants/staticData";

export const INITIAL_STATE = {
    id: null,
    code: null,
    lab: null,
    dnp: null,
    patients: null,
    geometry: null,
};

export default function regionReducer (state = INITIAL_STATE, {type, payload}) {
    switch(type) {
        case STATIC_DATA_FETCH_FULFILLED : {
            const {id, code, lab, dnp, patients, geometry} = payload;
            return {...state, id, code, lab, dnp, patients, geometry};
        }
    }
    return state;
};