import {ACTION_CHANGE_ETAB_LIST} from "../../constants/map";
import {CENTRE_EDIT_IS_CLIENT_FULFILLED} from "../../constants/staticData";

const INITIAL_STATE = {
    typeGeo: null,
    status: null,
    isClient: null,
    isSdc: null,
    champ: null,
    min: 0,
    max: 0,
    healthDivisions: {},
    regions: {},
    uk: {},
    totalRegion: 0,
    totalHealthDivision: 0,
    totalUK: 0,
};

export default function centreReducer (state = INITIAL_STATE, {type, payload}) {
    switch (type) {
        case CENTRE_EDIT_IS_CLIENT_FULFILLED: {
            const { centre } = payload;
            if (! state.isClient) {
                return state;
            }
            if (centre.isClient) {
                return {
                    ...state,
                    regions: {
                        ...state.regions,
                        [centre.idReg]: [
                            ...state.regions[centre.idReg],
                            centre
                        ]
                    },
                    healthDivisions: {
                        ...state.healthDivisions,
                        [centre.idHD]: [
                            ...state.healthDivisions[centre.idHD],
                            centre
                        ]
                    },
                    uk: {
                        ...state.uk,
                        [centre.id]: centre
                    },
                    totalRegion: state.totalRegion + 1,
                    totalHealthDivision: state.totalHealthDivision + 1,
                    totalUK: state.totalUK + 1
                };
            }

            const { [centre.id]: _, ...uk } = state.uk;
            return {
                ...state,
                regions: {
                    ...state.regions,
                    [centre.idReg]: state.regions[centre.idReg].filter(e => e.id !== centre.id)
                },
                healthDivisions: {
                    ...state.healthDivisions,
                    [centre.idHD]: state.healthDivisions[centre.idHD].filter(e => e.id !== centre.id)
                },
                uk,
                totalRegion: state.totalRegion > 0 ? state.totalRegion - 1 : state.totalRegion,
                totalHealthDivision: state.totalHealthDivision > 0 ? state.totalHealthDivision - 1 : state.totalHealthDivision,
                totalUK: state.totalUK > 0 ? state.totalUK - 1 : state.totalUK
            };
        }
        case ACTION_CHANGE_ETAB_LIST: {
            return {
                ...state,
                ...payload
            };
        }
    }

    return state;
}