import {
    ACTION_FETCH_SIMULATIONS_ERROR,
    ACTION_FETCH_SIMULATIONS_FULFILLED,
    ACTION_FETCH_SIMULATIONS_PENDING
} from "../../constants/map";
import {isSimulationsListParamsHasChange} from "../../managers/mapParamsManager";
import {ACTION_LOGOUT} from "../../constants/authentication";
import {
    SIMULATION_CREATION_SUCCESS,
    SIMULATION_FETCH_REMOVE,
    SIMULATION_RENAME_FULFILLED
} from "../../constants/simulation";
import {arrayUpdate} from "../../utils/immutableArray";

export const getNextRenamedSimulation = (state, payload) => {
    const {id, lab} = payload;
    if (!state.centres) return state;
    const indexes = state.centres.reduce((accu1, {simulations}, i) => {
        const simIndex = simulations.reduce((accu2, {id: simId}, j) => parseInt(id, 10) === simId ? j : accu2, -1);
        if(simIndex !== -1) return [i, simIndex];

        return accu1;
    }, []);

    if(indexes.length !== 2) return state;

    return {
        ...state,
        centres: arrayUpdate(
            state.centres,
            indexes[0],
            {
                ...state.centres[indexes[0]],
                simulations: arrayUpdate(
                    state.centres[indexes[0]].simulations,
                    indexes[1],
                    {
                        ...state.centres[indexes[0]].simulations[indexes[1]],
                        lab
                    }
                )
            }
        )
    }
}

const INITIAL_STATE = {
    pending: false,
    error: false,
    params: null,
    centres: [],
}

export default function simulationsListReducer (state = INITIAL_STATE, {type, payload}) {
    switch (type) {
        case ACTION_FETCH_SIMULATIONS_PENDING: {
            const {params} = payload;

            return {
                ...state,
                params,
                error: false,
                pending: true
            }
        }
        case ACTION_FETCH_SIMULATIONS_FULFILLED: {
            const {params, data} = payload;
            if(isSimulationsListParamsHasChange(state.params, params)) return state;

            return {
                ...state,
                pending: false,
                centres: data
            }
        }
        case ACTION_FETCH_SIMULATIONS_ERROR: {
            const {params} = payload;
            if(isSimulationsListParamsHasChange(state.params, params)) return state;

            return {
                ...state,
                error: true,
                pending: false,
            }
        }
        case SIMULATION_RENAME_FULFILLED: {
           return getNextRenamedSimulation(state, payload);
        }
        case SIMULATION_CREATION_SUCCESS:
        case SIMULATION_FETCH_REMOVE:
        case ACTION_LOGOUT: {
            return {
                ...state,
                ...INITIAL_STATE
            }
        }
    }
    return state;
}