import i18n from "../../../../internationalization/i18n";

export const intChangeFormater = value => {
    if(`${value}` === '') return value;

    let formatedValue = `${value}`.replace(/[^0-9]/g, '');
    if(formatedValue === '0') return formatedValue;
    formatedValue = formatedValue.replace(/^0/, '');
    return formatedValue
}

export const intBlurFormater = value => {
    if(`${value}` === '') return 0;
    return intChangeFormater(value);
}

export const floatChangeFormater = value => {
    if(`${value}` === '') return value;

    let formatedValue = `${value}`.replace(/,/g, '.');
    formatedValue = formatedValue.replace(/[^0-9.]/g, '');
    let i = 0;
    formatedValue = formatedValue.replace(/\./g, () => {
        i++;
        return i > 1 ? '' : '.';
    });

    return formatedValue;
}

export const floatBlurFormater = value => {
    if(`${value}` === '') return 0;

    let formatedValue = `${value}`.replace(/,/g, '.');
    formatedValue = formatedValue.replace(/[^0-9.]/g, '');
    const parts = formatedValue.split('.');
    let intergerPart = parts[0];
    if(intergerPart.length === 0){
        intergerPart = '0';
    }
    let decimalPart = "";
    for(let i = 1; i < parts.length; i++) {
        decimalPart = `${decimalPart}${parts[i]}`;
    }
    if(decimalPart.length === 0) {
        return `${intergerPart}`;
    }
    const separator = i18n.language !== 'en' ? ',' : '.';
    return `${intergerPart}${separator}${decimalPart}`;
}

export const timeChangeFormater = value => {
    if(`${value}` === '') return 'h';

    let formatedValue = `${value}`.replace(/[^0-9h]/g, '');
    let i = 0;
    formatedValue = formatedValue.replace(/h/g, () => {
        i++;
        return i > 1 ? '' : 'h';
    });
    const matches = formatedValue.match(/([0-9][0-9])([0-9])h/);
    if(matches) {
        formatedValue = `${matches[1]}h${matches[2]}`;
    }

    let isHeure = true;
    let isMinute = false;
    let heure = '';
    let minute = '';
    for(let i = 0; i < formatedValue.length; i++) {
        const char = formatedValue.charAt(i);
        if(char === 'h') {
            isHeure = false;
            isMinute = true;
        } else {
            if (isHeure) {
                heure = `${heure}${char}`;
                if(heure.length === 2) isHeure = false;
            }
            if (isMinute) {
                minute = `${minute}${char}`;
                if(minute.length === 2) isMinute = false;
            }
        }
    }
    if(heure !== '' && parseInt(heure, 10) > 23) heure = '23';
    if(minute !== '' && parseInt(minute, 10) > 59) minute = '59';

    if(i18n.language !== 'en') {
        return `${heure}h${minute}`;
    }
    return `${heure}:${minute}`;
}

export const timeBlurFormater = value => {
    const formatedValue = timeChangeFormater(value);
    let matches = formatedValue.match(/([0-9]*):([0-9]*)/);
    if(i18n.language !== 'en') {
        matches = formatedValue.match(/([0-9]*)h([0-9]*)/);
    }

    let heure = 0;
    let minute = 0;
    if(matches && matches[1] && matches[1] !== '') heure = parseInt(matches[1], 10);
    if(matches && matches[2] && matches[2] !== '') minute = parseInt(matches[2], 10);
    if(heure < 10) heure = `0${heure}`;
    if(minute < 10) minute = `0${minute}`;

    if(i18n.language !== 'en') {
        return `${heure}h${minute}`;
    }
    return `${heure}:${minute}`;
}

export const percentChangeFormater = value => {
    if(`${value}` === '') return '%';
    let formatedValue = `${value}`.replace(/[^0-9]/g, '');
    formatedValue = intChangeFormater(formatedValue);

    return `${formatedValue}%`;
}

export const percentBlurFormater = value => {
    if(`${value}` === '' || `${value}` === '%') return '0%';

    return percentChangeFormater(value);
}


export const valueChangeFormater = (value, type) => {
    if(type === 'int') {
        return intChangeFormater(value);
    }
    if(type === 'float') {
        return floatChangeFormater(value);
    }
    if(type === 'time') {
        return timeChangeFormater(value);
    }
    if(type === 'percent') {
        return percentChangeFormater(value);
    }

    return value;
}


export const valueBlurFormater = (value, type) => {
    if(type === 'int') {
        return intBlurFormater(value);
    }
    if(type === 'float') {
        return floatBlurFormater(value);
    }
    if(type === 'time') {
        return timeBlurFormater(value);
    }
    if(type === 'percent') {
        return percentBlurFormater(value);
    }

    return value;
}