import React from "react";
import Typography from "@material-ui/core/Typography";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Link from "@material-ui/core/Link";
import {Table, TableBody, TableCell, TableHead, TableRow} from "@material-ui/core";

const useStyles = makeStyles(theme => ({
    container: {
        padding: '30px 31px 30px 45px',
    },
    block: {
        marginBottom: '24px',
    },
    h4: {
        textAlign: 'center',
        marginBottom: '60px',
    },
    h5: {
        marginBottom: '16px'
    },
    spaces: {
        marginLeft: '28px'
    },
    link: {
        color: theme.palette.link.main,
        borderCollapse: 'separate'
    },
    head: {
        '& .MuiTypography-body2': {
            textAlign: 'center',
            minWidth: '44px',
        },
        '& .MuiTableCell-head': {
            paddingLeft: 0,
            paddingRight: 0,
            '&:first-child': {
                width: '162px'
            },
            '&:last-child': {
                width: '165px'
            },
        }
    },
    body: {
        '& .MuiTypography-subtitle1': {
            textAlign: 'center'
        },
    }
}));

const ReferencesContent = () => {
    const classes = useStyles();

    return <div className={classes.container}>
        <div className={classes.h4}><Typography variant="h4">References and Methods</Typography></div>
        <div><Typography variant="h6">1. Populations registered within each Integrated Care Board (England) or Health Board (Devolved Nations)</Typography></div>
        <div className={classes.block}>
            <p>
                <Typography>
                    Populations of ICBs and Health Boards are based on NHS data available online, we expect these to be
                    updated annually and in each case the most recent data on each of the following links will be used.
                </Typography>
            </p>
            <p>
                <Typography>
                    England - <Link target="_blank" href="https://digital.nhs.uk/data-and-information/publications/statistical/patients-registered-at-a-gp-practice/december-2022" rel="noopener" className={classes.link}> https://digital.nhs.uk/data-and-information/publications/statistical/patients-registered-at-a-gp-practice/december-2022</Link>
                </Typography>
            </p>
            <p>
                <Typography>
                    Wales - <Link target="_blank" href="https://statswales.gov.wales/Catalogue/Population-and-Migration/Population/Estimates/Local-Health-Boards/populationestimates-by-lhb-age" rel="noopener" className={classes.link}> https://statswales.gov.wales/Catalogue/Population-and-Migration/Population/Estimates/Local-Health-Boards/populationestimates-by-lhb-age</Link>
                </Typography>
            </p>
            <p>
                <Typography>
                    Scotland - <Link target="_blank"  href="https://www.opendata.nhs.scot/dataset/population-estimates/resource/27a72cc8-d6d8-430c-8b4f-3109a9ceadb1" className={classes.link}>Population Estimates - Health Board (2019) Population Estimates - Scottish Health and Social Care Open Data (nhs.scot)</Link>
                </Typography>
            </p>
            <p>
                <Typography>
                    Northern Ireland - <Link target="_blank" href="https://www.publichealth.hscni.net/publications/director-public-health-core-tables-2020-supporting-director-public-health-annual" className={classes.link}>Director of Public Health Core Tables 2020 – Supporting the Director of Public Health Annual Report 2021 | HSC Public Health Agency (hscni.net)</Link>
                </Typography>
            </p>
        </div>
        <div><Typography variant="h6">2. Eligible patients</Typography></div>
        <div className={classes.block}>
            <p>
                <Typography>
                    The total number of patients who could be considered for treatment with Qutenza in each NHS area is
                    calculated based on the populations of each as follows.
                </Typography>
            </p>
            <p>
                <Typography>
                    <b>
                        Diabetes – The number of patients with diabetes is taken from the following publications of diabetes
                        data:
                    </b>
                </Typography>
                <Typography>
                    <span className={classes.spaces} />
                    • Northern Ireland - Department of Health – Raw disease Prevalence data -  Accessed online on 13/3/23 at
                    <Link target="_blank" href="https://www.health-ni.gov.uk/sites/default/files/publications/health/rdptd-tables-2022_0.XLSX"
                          rel="noopener" className={classes.link}> https://www.health-ni.gov.uk/sites/default/files/publications/health/rdptd-tables-2022_0.XLSX</Link>
                </Typography>
                <Typography>
                    <span className={classes.spaces} />
                    • Scotland - Scottish Diabetes Survey 2021– Table 38. Accessed on line on 13/3/23 at
                    <Link target="_blank" href="https://www.diabetesinscotland.org.uk/wp-content/uploads/2023/02/Diabetes-Scottish-Diabetes-Survey-2021-final-version.pdf"
                          rel="noopener" className={classes.link}> Diabetes-Scottish-Diabetes-Survey-2021-final-version.pdf (diabetesinscotland.org.uk)</Link>
                </Typography>
                <Typography>
                    <span className={classes.spaces} />
                    • Wales - Sum of Type 1 and Type 2 registrations in each University Health Board. Accessed online on 13/3/23 at
                    <Link target="_blank" href="https://files.digital.nhs.uk/F0/19035D/National%20Diabetes%20Audit%202021-22%20Data%20Release%2C%20Wales.xlsx"
                          rel="noopener" className={classes.link}> https://files.digital.nhs.uk/F0/19035D/National%20Diabetes%20Audit%202021-22%20Data%20Release%2C%20Wales.xlsx</Link>
                </Typography>
                <Typography>
                    <span className={classes.spaces} />
                    • England - Sum of Type 1 and Type 2 registrations in each Integrated Care Board. Accessed online on 13/3/23 at
                    <Link target="_blank" href="https://files.digital.nhs.uk/14/38E358/National%20Diabetes%20Audit%202021-22%20Data%20Release%2C%20England.xlsx"
                          rel="noopener" className={classes.link}> https://files.digital.nhs.uk/14/38E358/National%20Diabetes%20Audit%202021-22%20Data%20Release%2C%20England.xlsx</Link>
                </Typography>
                <Typography>
                    <span className={classes.spaces} />
                    • The prevalence rate for NP among Diabetes patients (18.5%) is assumed from multiple studies based
                    on US assumptions and is in line with NICE estimates – which show a range of 16% to 26% of people
                    with diabetes (accessed on 13/3/23 at <Link target="_blanl" href="https://cks.nice.org.uk/topics/neuropathic-pain-drug-treatment/background-information/prevalence/" className={classes.link}>Prevalence | Background information | Neuropathic pain - drug treatment | CKS | NICE</Link>)
                </Typography>
            </p>
            <p>
                <Typography>
                    <b>
                        Cancer - The number of patients with cancer is calculated using the population in each NHS area
                        multiplied by the prevalence of cancer from USA data for 2020 via the WHO International Agency for
                        Research on Cancer (which contains comprehensive population estimates).
                    </b>
                </Typography>
            </p>
            <p>
                <Typography>
                    <b>> 3 year Cancer Prevalence - International Agency for Research on Cancer 2020 (</b>
                    <Link target="_blank" href="https://gco.iarc.fr/today/online-analysis-table?v=2020&mode=cancer&mode_population=continents&population=900&populations=840&key=asr&sex=0&cancer=39&type=2&statistic=3&prevalence=1&population_group=0&ages_group%5B%5D=0&ages_group%5B%5D=17&group_cancer=1&include_nmsc=1&include_nmsc_other=1#collapse-group-1-3" rel="noopener" className={classes.link}>
                        https://gco.iarc.fr/today/online-analysis-table?v=2020&mode=cancer&mode_population=continents&population=900&populations=840&key=asr&sex=0
                        &cancer=39&type=2&statistic=3&prevalence=1&population_group=0&ages_group%5B%5D=0&ages_group%5B%5D=17
                        &group_cancer=1&include_nmsc=1&include_nmsc_other=1#collapse-group-1-3
                    </Link>
                    <b>)</b>
                </Typography>
            </p>
            <p>
                <Typography>
                    <b>Other Neuropathic Pain is based on national prevalence estimated multiplied by the population in each NHS area</b>
                </Typography>
                <Typography>
                    <span className={classes.spaces} />
                    • Pain Management - 2012 LPNP Recommendations. Mick et. al (2012) (
                    <Link target="_blank" href="https://www.futuremedicine.com/doi/10.2217/pmt.11.77" rel="noopener" className={classes.link}>https://www.futuremedicine.com/doi/10.2217/pmt.11.77</Link>
                    )
                </Typography>
            </p>
            <p>
                <Typography>
                    These values are combined to estimate in each of the above aetiologies how many of the prevalent
                    population are expected to have been diagnosed with neuropathic pain and to have been pre-treated as
                    per current guidelines with first and second line oral therapies using the matrix below.
                </Typography>
            </p>
        </div>
        <div className={classes.block}>
            <Table aria-label="simple table">
                <TableHead className={classes.head}>
                    <TableRow>
                        <TableCell/>
                        <TableCell className={classes.headCell}><Typography variant="body2">% Population with indication + NP</Typography></TableCell>
                        <TableCell className={classes.headCell}><Typography variant="body2">% of Indication with NP</Typography></TableCell>
                        <TableCell className={classes.headCell}><Typography variant="body2">Diagnosed</Typography></TableCell>
                        <TableCell className={classes.headCell}><Typography variant="body2">Treated</Typography></TableCell>
                        <TableCell className={classes.headCell}><Typography variant="body2">2nd Line LNP</Typography></TableCell>
                        <TableCell className={classes.headCell}><Typography variant="body2">Rx for localized<br/>neuropathic pain (LNP, %)</Typography></TableCell>
                    </TableRow>
                </TableHead>
                <TableBody className={classes.body}>
                    <TableRow>
                        <TableCell><Typography variant="body2">% of Diabetic Patients with LNP</Typography></TableCell>
                        <TableCell />
                        <TableCell><Typography variant="subtitle1">18.5%</Typography></TableCell>
                        <TableCell><Typography variant="subtitle1">75.0%</Typography></TableCell>
                        <TableCell><Typography variant="subtitle1">83.0%</Typography></TableCell>
                        <TableCell><Typography variant="subtitle1">30.0%</Typography></TableCell>
                        <TableCell><Typography variant="subtitle1">62.9%</Typography></TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell><Typography variant="body2">% of CIPN Patients with LNP</Typography></TableCell>
                        <TableCell />
                        <TableCell><Typography variant="subtitle1">8.6%</Typography></TableCell>
                        <TableCell><Typography variant="subtitle1">70.0%</Typography></TableCell>
                        <TableCell><Typography variant="subtitle1">80.0%</Typography></TableCell>
                        <TableCell><Typography variant="subtitle1">50.0%</Typography></TableCell>
                        <TableCell><Typography variant="subtitle1">51.4%</Typography></TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell><Typography variant="body2">% Population with LNP resulting from surgery</Typography></TableCell>
                        <TableCell><Typography variant="subtitle1">0.69%</Typography></TableCell>
                        <TableCell></TableCell>
                        <TableCell><Typography variant="subtitle1">50.0%</Typography></TableCell>
                        <TableCell><Typography variant="subtitle1">83.0%</Typography></TableCell>
                        <TableCell><Typography variant="subtitle1">45.0%</Typography></TableCell>
                        <TableCell><Typography variant="subtitle1">71.1%</Typography></TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        </div>
        <div><Typography variant="h6">3. Hourly rates for NHS staff</Typography></div>
        <div className={classes.block}>
            <p>
                <Typography>
                    Unit Costs of Health and Social Care 2021, Personal Social Services Research Unit (PSSRU).
                    This report is a compilation of average costs in England by staff type and band and includes
                    overheads and other costs in the hourly rate as well as salary costs. Hospital Nurses are section
                    13 and Hospital doctors section 14.
                </Typography>
            </p>
            <p>
                <Link target="_blank" href="https://www.pssru.ac.uk/project-pages/unit-costs/unit-costs-of-health-and-social-care-2021/" className={classes.link}>
                    Unit Costs of Health and Social Care 2021 | PSSRU
                </Link>
            </p>
        </div>
        <div><Typography variant="h6">4. HRG and outpatient costs - NHS England (NHSE) 2022/23 Tariff Annex A (Nov. 22 update)</Typography></div>
        <div className={classes.block}>
            <p>
                <Typography>
                    The devolved nations do not produce tariff cost books to same detail as NHS England.
                    We have used the cost framework from the NHSE tariff for all NHS systems in the UK as proxy for costs.
                </Typography>
            </p>
            <p>
                <Link target="_blank" href="https://view.officeapps.live.com/op/view.aspx?src=https%3A%2F%2Fwww.england.nhs.uk%2Fwp-content%2Fuploads%2F2020%2F11%2F22-23NT_AnnexA-National-tariff-workbook-Nov22-1.xlsx&wdOrigin=BROWSELINK" className={classes.link}>
                    22-23NT_AnnexA-National-tariff-workbook-Nov22-1.xlsx (live.com)
                </Link>
            </p>
        </div>

    </div>
}
export default ReferencesContent;