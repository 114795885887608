export const DAYS = [
    'lundi',
    'mardi',
    'mercredi',
    'jeudi',
    'vendredi',
    'samedi',
    'dimanche',
];

export const SERVICES = [
    'pain-service',
    'community-based-pain-clinic',
    'oncologie',
    'palliatif-care',
    'post-surgery',
    'diabete',
    'physiotherapy',
    'other',
];


/**
 * VALEURS ENREGISTRÉES
 */
export const ANNUAL_STAY                = "annual-stay";
export const ANNUAL_CONSUMABLE          = "annual-consumable";
export const CONSUMABLE_PER_DAY         = "consumable-per-day";
export const CONSUMABLE_SMALL_AREA      = "consumable-small-area";
export const CONSUMABLE_AVERAGE_AREA    = "consumable-average-area";
export const CONSUMABLE_LARGE_AREA      = "consumable-large-area";
export const TOTAL_BED                  = "total-bed";
export const TIME_BED_STAY_HOUR         = "time-bed-stay-hour";
export const TIME_IDE_STAY_HOUR         = "temps-ide-sejour-heure";
export const TIME_IDE_BAND_4_HOUR       = "time-ide-band-4-hour";
export const TIME_IDE_BAND_5_HOUR       = "time-ide-band-5-hour";
export const TIME_IDE_BAND_6_HOUR       = "time-ide-band-6-hour";
export const TIME_IDE_BAND_7_HOUR       = "time-ide-band-7-hour";
export const TIME_IDE_BAND_TOTAL        = "time-ide-band-total";
export const TIME_PHYSIO_PODIA          = "time-physio-podia";
export const TIME_MEDICAL_CONSULTANT    = "time-medical-consultant";
export const COST_IDE_BAND_4_HOUR       = "cost-ide-band-4-hour";
export const COST_IDE_BAND_5_HOUR       = "cost-ide-band-5-hour";
export const COST_IDE_BAND_6_HOUR       = "cost-ide-band-6-hour";
export const COST_IDE_BAND_7_HOUR       = "cost-ide-band-7-hour";
export const COST_IDE_BAND_TOTAL        = "cost-ide-band-total";
export const COST_PHYSIO_PODIA          = "cost-physio-podia";

export const AMOUNT_NHS                 = "amount-nhs";
export const AMOUNT_NHS_TYPE_LIST       = "amount-nhs-type-list";
export const AMOUNT_NHS_TYPE            = "amount-nhs-type";
export const AMOUNT_YEAR                = "amount-nhs-year";
export const COEFF_GEOGRAPHIQUE         = "coeff-geographique";
export const OTHER_INCOME               = "other-income";
export const CONSUMABLE_COST            = "consumable-cost";
export const CONSUMABLE_PROPORTION      = "rebate-consommable-percent";
export const COST_MEDICAL_CONSULTANT    = "cost-medecal-consultant";
export const DEPENSE_IDE                = "depense-ide";
export const DEPENSE_STRUCTURE          = "depense-structure";
export const COST_OTHER                 = "cost-other";

export const DAY_AM_TIME                = '-am-time';
export const DAY_AM_TIME_BEGIN          = '-am-time-begin';
export const DAY_AM_TIME_END            = '-am-time-end';
export const DAY_AM_BED                 = '-am-bed';
export const DAY_PM_TIME                = '-pm-time';
export const DAY_PM_TIME_BEGIN          = '-pm-time-begin';
export const DAY_PM_TIME_END            = '-pm-time-end';
export const DAY_PM_BED                 = '-pm-bed';
export const PLANNING_EDITABLE_CHAMPS   = [
    DAY_AM_TIME,
    DAY_AM_TIME_BEGIN,
    DAY_AM_BED,
    DAY_PM_TIME,
    DAY_PM_TIME_BEGIN,
    DAY_PM_BED,
];


export const VALUE_NHS                  = 'value-nhs-';
export const NHS_CODE_AB24Z             = 'nhs-code-ab24z';
export const NHS_CODE_AB26Z             = 'nhs-code-ab26z';
export const NHS_CODE_WF01A             = 'nhs-code-wf01a';
export const NHS_CODE_WF01B             = 'nhs-code-wf01b';
export const NHS_CODE_WF02A             = 'nhs-code-wf02a';
export const NHS_CODE_WF02B             = 'nhs-code-wf02b';

export const NHS_TYPE_HRG               = 'hrg';
export const NHS_TYPE_OUTPATIENT        = 'outpatient';
export const NHS_TYPE_LOCAL             = 'local';

export const NHS_TYPES_CODES = {
    [NHS_TYPE_HRG]: [
        NHS_CODE_AB24Z,
        NHS_CODE_AB26Z,
    ],
    [NHS_TYPE_OUTPATIENT]: [
        NHS_CODE_WF01A,
        NHS_CODE_WF01B,
        NHS_CODE_WF02A,
        NHS_CODE_WF02B,
    ],
    [NHS_TYPE_LOCAL]: []
}

export const SERVICE_SEJOUR_TOTAL_ANNUEL            = "-sejour-total-annuel"
export const SERVICE_PART_SEJOUR_DOULOUREUX_ANNUEL  = "-part-sejour-douloureux-annuel"
export const SERVICE_CONSOMMATION_PAR_ZONE          = "-consommation-par-zone"
export const SERVICE_EDITABLE_CHAMPS                = [
        SERVICE_SEJOUR_TOTAL_ANNUEL,
        SERVICE_PART_SEJOUR_DOULOUREUX_ANNUEL,
        SERVICE_CONSOMMATION_PAR_ZONE,
];

export const EDITABLE_FIELDS = [
    ANNUAL_STAY,
    ANNUAL_CONSUMABLE,
    CONSUMABLE_PER_DAY,
    CONSUMABLE_SMALL_AREA,
    CONSUMABLE_AVERAGE_AREA,
    CONSUMABLE_LARGE_AREA,
    TOTAL_BED,
    TIME_BED_STAY_HOUR,
    TIME_IDE_STAY_HOUR,
    TIME_IDE_BAND_4_HOUR,
    TIME_IDE_BAND_5_HOUR,
    TIME_IDE_BAND_6_HOUR,
    TIME_IDE_BAND_7_HOUR,
    COST_IDE_BAND_4_HOUR,
    COST_IDE_BAND_5_HOUR,
    COST_IDE_BAND_6_HOUR,
    COST_IDE_BAND_7_HOUR,
    COST_PHYSIO_PODIA,
    TIME_PHYSIO_PODIA,
    TIME_MEDICAL_CONSULTANT,
    AMOUNT_NHS,
    AMOUNT_YEAR,
    COEFF_GEOGRAPHIQUE,
    OTHER_INCOME,
    CONSUMABLE_COST,
    CONSUMABLE_PROPORTION,
    COST_MEDICAL_CONSULTANT,
    DEPENSE_IDE,
    DEPENSE_STRUCTURE,
    COST_OTHER,
    ...DAYS.reduce((accu, day) => [...accu, ...PLANNING_EDITABLE_CHAMPS.map(champ => `${day}${champ}`)], []),
    ...SERVICES.reduce((accu, service) => [...accu, ...SERVICE_EDITABLE_CHAMPS.map(champ => `${service}${champ}`)], []),
    VALUE_NHS,
    NHS_CODE_AB24Z,
    NHS_CODE_AB26Z,
    NHS_CODE_WF01A,
    NHS_CODE_WF01B,
    NHS_CODE_WF02A,
    NHS_CODE_WF02B,
    AMOUNT_NHS_TYPE,
]

export const SIMPLE_OPTIMISATION_CHAMPS = [
    ANNUAL_STAY,
    CONSUMABLE_PER_DAY,
    TIME_BED_STAY_HOUR,
    TIME_IDE_BAND_4_HOUR,
    TIME_IDE_BAND_5_HOUR,
    TIME_IDE_BAND_6_HOUR,
    TIME_IDE_BAND_7_HOUR,
];

export const SERVICE_OPTIMISATION_CHAMPS = [
    CONSUMABLE_SMALL_AREA,
    CONSUMABLE_AVERAGE_AREA,
    CONSUMABLE_LARGE_AREA,
    TIME_BED_STAY_HOUR,
    TIME_IDE_BAND_4_HOUR,
    TIME_IDE_BAND_5_HOUR,
    TIME_IDE_BAND_6_HOUR,
    TIME_IDE_BAND_7_HOUR,
    ...SERVICES.reduce((accu, service) => [...accu, ...SERVICE_EDITABLE_CHAMPS.map(champ => `${service}${champ}`)], []),
];

export const PLANNING_OPTIMISATION_CHAMPS = [
    CONSUMABLE_PER_DAY,
    TIME_BED_STAY_HOUR,
    TIME_IDE_BAND_4_HOUR,
    TIME_IDE_BAND_5_HOUR,
    TIME_IDE_BAND_6_HOUR,
    TIME_IDE_BAND_7_HOUR,
    ...DAYS.reduce((accu, day) => [...accu, ...PLANNING_EDITABLE_CHAMPS.map(champ => `${day}${champ}`)], []),
];

export const PERSPECTIVE_CHAMPS = [
    ANNUAL_STAY,
    CONSUMABLE_PER_DAY,
    TIME_IDE_STAY_HOUR,
    AMOUNT_NHS,
    AMOUNT_NHS_TYPE,
    COEFF_GEOGRAPHIQUE,
    CONSUMABLE_COST,
    COST_MEDICAL_CONSULTANT,
    DEPENSE_IDE,
    TIME_IDE_STAY_HOUR,
    DEPENSE_STRUCTURE,
    COST_OTHER,
    OTHER_INCOME,
    TIME_BED_STAY_HOUR,
    CONSUMABLE_PROPORTION,
    TIME_MEDICAL_CONSULTANT,
    TIME_IDE_BAND_4_HOUR,
    TIME_IDE_BAND_5_HOUR,
    TIME_IDE_BAND_6_HOUR,
    TIME_IDE_BAND_7_HOUR,
    COST_IDE_BAND_4_HOUR,
    COST_IDE_BAND_5_HOUR,
    COST_IDE_BAND_6_HOUR,
    COST_IDE_BAND_7_HOUR,
    COST_PHYSIO_PODIA,
    TIME_PHYSIO_PODIA,
    ...DAYS.reduce((accu, day) => [...accu, ...PLANNING_EDITABLE_CHAMPS.map(champ => `${day}${champ}`)], []),
    ...SERVICES.reduce((accu, service) => [...accu, ...SERVICE_EDITABLE_CHAMPS.map(champ => `${service}${champ}`)], []),
]