import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import detector from "i18next-browser-languagedetector";

import translationFR from '../locales/fr/translation';
import routingFR from '../locales/fr/routing';
import translationEN from '../locales/en/translation';
import routingEN from '../locales/en/routing';
import moment from "moment";

export const defaultLocale = 'en';

export const locales = {
    en: {
        code: 'en',
        codeIso: 'en_EN',
        translation: translationEN,
        routing: routingEN,
    }
};

export function hasMmultiLanguages() {
    return true; //For the moment disable French language
    return Object.keys(locales).length > 1;
}

export function getLocaleFromPath(path) {
    if(!hasMmultiLanguages()) {
        return defaultLocale;
    }
    if (path === "/" || path.match(/\//) === null) {
        return defaultLocale
    }
    const locale = path.split('/')[1];

    if(locales[locale] === undefined) {
        return defaultLocale
    }

    return locale;
}

export function switchHtmlLocale (locale) {
    const html =  window.document.documentElement;
    html.lang = locale
}

export function setLocale(locale, init = false) {
    switchHtmlLocale(locale);
    if(!init) {
        i18n.changeLanguage(locale);
    }
}

export const init = locale => {
    if(!locale || locales[locale] === undefined) {
        locale = defaultLocale;
    }
    i18n
        .use(detector)
        .use(initReactI18next) // passes i18n down to react-i18next
        .init({
            resources: locales,
            lng: locale,
            fallbackLng: Object.keys(locales),
            interpolation: {
                escapeValue: false // react already safes from xss
            }
        });

    i18n
        .on('languageChanged', lng => {
            moment.locale(lng);
        })

    setLocale(locale);
};

export default i18n;