import {
    DEFAULT_VALUE_ADD_NHS_TYPE,
    DEFAULT_VALUES_FETCH_FULFILLED,
    DEFAULT_VALUES_SET,
} from "../../constants/defaultValues";
import defaultValueNHSTypeReducer, {INITIAL_STATE as NHS_TYPE_INITIAL_STATE} from "./defaultValueNHSTypeReducer";
import {arrayAdd, arrayUpdate} from "../../utils/immutableArray";

export const INITIAL_STATE = {
    slug: null,
    value: [],
    error: false
}

export default function defaultValuesNHSReducer (state = INITIAL_STATE, {type, payload}) {
    switch (type) {
        case DEFAULT_VALUES_FETCH_FULFILLED: {
            const {slug, value} = payload;

            return {
                ...state,
                slug,
                value: value.map(({type: nhsType, id}) => defaultValueNHSTypeReducer(NHS_TYPE_INITIAL_STATE, {type, payload: {type: nhsType, id}}))
            }
        }
        case DEFAULT_VALUES_SET: {
            const {index, value, error} = payload;
            const newValue = arrayUpdate(state.value, index, defaultValueNHSTypeReducer(state.value[index], {type, payload: {value}}));

            return {
                ...state,
                value: newValue,
                error: error || !!newValue.find((val) => val.error === true)
            }
        }
        case DEFAULT_VALUE_ADD_NHS_TYPE: {
            const {type} = payload;
            const newValue = arrayAdd(state.value, 0, defaultValueNHSTypeReducer(NHS_TYPE_INITIAL_STATE, {type, payload}));

            return {
                ...state,
                value: newValue,
            }
        }
    }
    return state;
}