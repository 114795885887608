import React, {useState} from "react";
import PropTypes from "prop-types";
import makeStyles from "@material-ui/core/styles/makeStyles";
import {Typography} from "@material-ui/core";
import NhsCodes from "./NhsCodes";


const useStyles = makeStyles(theme => ({
    title: {
        height: '30px',
        marginBottom: '8px',
    },
    codes: {
        width: '400px',
        display: 'flex',
        flexWrap: 'wrap',
    }
}));

const NhsYearCodes = ({index, year, codes, type, errorChecker}) => {
    const classes = useStyles();

    return (
        <>
            <div className={classes.title}>
                <Typography variant="h5" color="secondary">{year}</Typography>
            </div>
            <div className={classes.codes}>
                {codes.map(({code, percent, value}, key) => (
                    <NhsCodes
                        key={key}
                        year={year}
                        code={code}
                        type={type}
                        percent={percent}
                        value={value}
                        errorChecker={errorChecker}
                    />
                ))}
            </div>
        </>
    );
}

NhsYearCodes.propTypes = {
    index: PropTypes.number.isRequired,
    type: PropTypes.number.isRequired,
    year: PropTypes.number.isRequired,
    codes: PropTypes.array,
    errorChecker: PropTypes.func
};

NhsYearCodes.defaultProps = {
    codes: []
};

export default NhsYearCodes;
