import React, {useState} from "react";
import PropTypes from "prop-types";
import makeStyles from "@material-ui/core/styles/makeStyles";
import {useTranslation} from "react-i18next";
import {AMOUNT_NHS} from "../../../../constants/simulationFields";
import Field from "../../../Simulation/Edition/Field/Field";
import {setValue} from "../../../../actions/defaultValues/defaultValuesAction";
import {useDispatch} from "react-redux";
import {Typography} from "@material-ui/core";


const useStyles = makeStyles(theme => ({
    title: {
        marginBottom: "4px"
    },
    fieldContainer: {
        width: '160px',
        paddingRight: "16px"
    },
    container: {
    }
}));

const NhsCodes = ({type, year, code, percent, value, errorChecker}) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const {t} = useTranslation();

    const handleSave = (slug, inputValue) => {
        code = {
            type,
            year: year.toString(),
            code,
            percent: percent.replace('%', ''),
            value,
        };
        if (slug === 'percent') {
            code[slug] = inputValue.replace('%', '');
        } else {
            code[slug] = parseFloat(inputValue.replace('£', '').replace(',', ''));
        }
        dispatch(setValue(AMOUNT_NHS, code));
    }

    const handleError = () => {
        return errorChecker(type);
    }

    return (
        <div className={classes.container}>
            <div className={classes.title}>
                <Typography>{t(`simulation.fields.nhs-code-${code}.label`)}</Typography>
            </div>
            <Field
                type="percent"
                className={classes.fieldContainer}
                slug="percent"
                defaultValue={percent}
                onBlur={handleSave}
                errorChecker={handleError}
            />
            <Field
                type="currency"
                className={classes.fieldContainer}
                slug="value"
                defaultValue={value}
                onBlur={handleSave}
                errorChecker={handleError}
                helper={t('simulation.fields.nhs-helper')}
            />
        </div>
    );
}

NhsCodes.propTypes = {
    type: PropTypes.number.isRequired,
    year: PropTypes.number.isRequired,
    code: PropTypes.string.isRequired,
    percent: PropTypes.string,
    value: PropTypes.number,
    errorChecker: PropTypes.func
};

NhsCodes.defaultProps = {
    percent: '0',
    value: 0
};

export default NhsCodes;
