import React from "react";
import moment from "moment/moment";

class NumberFormatterFactory {

    constructor() {
        if (!NumberFormatterFactory.instance) {
            this.locale = 'en-GB';
            this.int = this.getFormatter(0);
            this.float = this.getFormatter(2);
            NumberFormatterFactory.instance = this;
        }
        return NumberFormatterFactory.instance;
    }

    getNow() {
        return moment(new Date().toLocaleString('en-EU', {timeZone: 'Europe/London'}))
            .format('MM/DD/YYYY - hh:mm a');
    }

    getMomentFormat(defaultTime) {
        const hours = Math.floor(defaultTime/60);
        const minutes = Math.round((defaultTime/60 - hours) * 60);
        const now = moment(new Date().toLocaleString('en-EU', {timeZone: 'Europe/London'}));
        now.hours(hours);
        now.minutes(minutes);
        return now
    }

    getPercent(value, total, isEvolution = false) {
        if (value === null || total === null) {
            return 'NA';
        }
        if(total === 0) {
            return this.getPriceValue(0, true, isEvolution);
        }

        return this.getPriceValue((value / total) * 100, true, isEvolution);
    }

    getValue(value, isFloat = false, isEvolution = false) {
        if (value === null) {
            return 'NA';
        }
        const hasDecimal = (value - Math.floor(value)) !== 0;
        const formatter = isFloat && hasDecimal || isEvolution
            ? this.float
            : this.int
        ;
        return formatter.format(value);
    }

    getPriceValue(value, isFloat = false, isEvolution = false) {
        if (value === null) {
            return 'NA';
        }
        const hasDecimal = (value - Math.floor(value)) !== 0;
        const formatter = isFloat && hasDecimal || isEvolution
            ? this.float
            : this.int
        ;
        let val = formatter.format(value);
        if (value < 0) {
            val = formatter.format(value * -1);
            return `-£${val}`;
        }
        return isEvolution && value > 0 ? `+£${val}` : `£${val}`;
    }

    getFormatter(nbDigits) {
        return new Intl.NumberFormat(this.locale, {
            minimumFractionDigits: nbDigits,
            maximumFractionDigits: nbDigits,
        });
    }

}

const numberFormatter = new NumberFormatterFactory();
export default numberFormatter;
