import { combineReducers } from 'redux';
import authenticationReducer from "./authentication/authenticationReducer";
import staticDataReducer from "./staticData/staticDataReducer";
import simulationsListReducer from "./map/simulationsListReducer";
import mapReducer from "./map/mapReducer";
import simulationReducer from "./simulation/simulationReducer";
import exportPDFReducer from "./simulation/exportPDFReducer";
import centreReducer from "./map/centreReducer";
import searchReducer from "./map/searchReducer";
import defaultValuesReducer from "./defaultValues/defaultValuesReducer";
import perspectiveReducer from "./perspective/perspectiveReducer";

export default () => {
    const rootReducer = {
        authentication: authenticationReducer,
        staticData: staticDataReducer,
        map: combineReducers({
            simulations: simulationsListReducer,
            map: mapReducer,
            centre: centreReducer,
            search: searchReducer
        }),
        simulation: simulationReducer,
        defaultValues: defaultValuesReducer,
        perspective: perspectiveReducer,
        export: exportPDFReducer
    };

    return combineReducers(rootReducer);
};