import React from "react";
import PropTypes from "prop-types";
import makeStyles from "@material-ui/core/styles/makeStyles";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {AMOUNT_NHS} from "../../../../constants/simulationFields";
import Paper from "../../../Simulation/Shared/Paper";
import Button from "@material-ui/core/Button";
import {addNewYearGhm} from "../../../../actions/defaultValues/defaultValuesAction";
import AddIcon from "@material-ui/icons/Add";
import {nhsCodesList} from "../../../../managers/defaultValueManager";
import NhsYearCodes from "./NhsYearCodes";


const useStyles = makeStyles(theme => ({
    rows: {
        display: 'flex',
        flexWrap: 'wrap',
        width: '320px',
        marginLeft: 'auto',
        marginRight: 'auto',
    }
}));

const NhsCost = ({idType, type, codes}) => {
    const classes = useStyles();
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const defaultNhsCost = useSelector(state => state.defaultValues.fields?.[AMOUNT_NHS]?.value);
    const yearCodesList = defaultNhsCost ? nhsCodesList(defaultNhsCost, codes) : null;
    if (!defaultNhsCost) return null;

    const checkCost = (index) => {
        return !defaultNhsCost[index].error;
    }

    const showNewYear = () => {
        return parseInt(Math.max(...Object.keys(yearCodesList)), 10) <= new Date().getFullYear();
    }

    const handleNewYear = () => {
        dispatch(addNewYearGhm(AMOUNT_NHS, yearCodesList[Math.max(...Object.keys(yearCodesList))]));
    }

    if(yearCodesList.length === 0) return null;
    return (
        <Paper title={t(`simulation.${type}`)}>
            <div className={classes.rows}>
                {yearCodesList.map((yearCodes, index) => (
                    <NhsYearCodes
                        key={`${type}-${index}`}
                        index={index}
                        year={index}
                        type={idType}
                        codes={yearCodes}
                        errorChecker={checkCost}
                    />
                ))}
                {showNewYear() && (
                    <Button variant="contained" onClick={handleNewYear} startIcon={<AddIcon />}>
                        {t('administration.fields.recettesSejours.new')}
                    </Button>
                )}
            </div>
        </Paper>
    );
}

NhsCost.propTypes = {
    idType: PropTypes.number,
    type: PropTypes.string,
    codes: PropTypes.array
};

NhsCost.defaultProps = {
    codes: []
};

export default NhsCost;
