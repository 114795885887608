import React, {useState} from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import PropTypes from "prop-types";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import {useGetRole, useIsClient} from "../../../managers/userManager";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import {updatePassword, updateUser} from "../../../actions/user/userAction";
import {USER_SHARED_INFORMATIONS} from "../../../constants/user";
import Form from "../../Form/Form";

const useStyles = makeStyles(theme => ({
    topBlock: {
        paddingBottom: "24px",
    },
    block: {
        paddingTop: "26px",
        paddingBottom: "24px",
        borderTop: `solid 1px ${theme.palette.divider}`,
    },
    emailBlock: {
        display: "flex",
        alignItems: "center",
    },
    passwordButton: {
        marginLeft: "16px"
    },
    actionsContainer: {
        display: "flex"
    },
    cancelButton: {
        marginRight: "16px"
    },
    formContainer: {
        marginTop: "6px"
    }
}));

const MonProfil = () => {
    const classes = useStyles();
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const user = useSelector(state => state.authentication.user);
    const error = useSelector(state => state.authentication.error);
    const pending = useSelector(state => state.authentication.fetchPending);
    const centres = useSelector(state => state.staticData.centres);
    const role = useGetRole();
    const isClient = useIsClient();
    const [show, setShow] = useState(false);

    const handleOpenChangePassword = () => {
        setShow(true);
    }
    const handleCancel = () => {
        setShow(false);
    }

    const handleSavePassword = (form) => {
        return new Promise((resolve, reject) => {
            dispatch(updatePassword(form));
            setShow(false);
        });
    }

    const confirmPassword = (values) => {
        let errors = [];
        if (values['password'] !== values['confirm']) {
            errors['confirm'] = t('administration.monProfil.error.confirm');
        }

        return errors
    }

    const handleChangeSharedInformations = () => {
        dispatch(updateUser(USER_SHARED_INFORMATIONS, !user.sharedInformations));
    }

    if (pending || !user) return null;

    return (
        <>
            <div className={classes.topBlock}>
                <Typography variant="h6">{user.firstname} {user.lastname}</Typography>
                <div className={classes.emailBlock}>
                    {!show && (
                        <>
                            <Typography>{user.email}</Typography>
                            <Button variant="contained" className={classes.passwordButton}
                                    onClick={handleOpenChangePassword} size="small">
                                {t('administration.monProfil.password')}
                            </Button>
                        </>
                    )}
                    {show && (
                        <Form
                            name="change-password"
                            fields={[
                                {
                                    name: 'password',
                                    type: 'password',
                                    required: true,
                                    defaultValue: ''
                                },
                                {
                                    name: 'confirm',
                                    type: 'password',
                                    required: true,
                                    defaultValue: ''
                                }
                            ]}
                            onCancel={handleCancel}
                            onSubmit={handleSavePassword}
                            validator={confirmPassword}
                            classes={{
                                formContainer: classes.formContainer,
                                actionsContainer: classes.actionsContainer,
                                cancelButton: classes.cancelButton
                            }}
                        />
                    )}
                </div>
            </div>
            <div className={classes.block}>
                <Typography variant="h6">{t(`administration.monProfil.roles.${role}`)}</Typography>
                {isClient && (
                    <Typography>{centres[user.centre].lib}</Typography>
                )}
            </div>
            {isClient && (
                <div className={classes.block}>
                    <Typography variant="h6">{t('administration.monProfil.infoShared')}</Typography>
                    <FormControlLabel
                        control={(
                            <Checkbox
                                checked={user.sharedInformations}
                                onChange={handleChangeSharedInformations}
                                color="primary"
                            />
                        )}
                        label={t('administration.monProfil.checkbox')}
                    />
                </div>
            )}
        </>
    );
};

MonProfil.propTypes = {
};

MonProfil.defaultProps = {
};

export default MonProfil;