import React from "react";
import PropTypes from "prop-types";
import {useIsErrorPage, useIsPublicPage} from "../hooks/router/useRouter";
import PublicLayout from "./PublicLayout";
import ConnectedLayout from "./ConnectedLayout";

const Layout = ({children, withHeaders = true}) => {
    const isPublicPage = useIsPublicPage();
    const isErrorPage = useIsErrorPage();

    if(isPublicPage || isErrorPage) {
        return (
            <PublicLayout>
                {children}
            </PublicLayout>
        );
    }

    return (
        <ConnectedLayout withHeaders={withHeaders}>
            {children}
        </ConnectedLayout>
    );
};

Layout.propTypes = {
    children: PropTypes.node
};

Layout.defaultProps = {
    children: null
};

export default Layout;