import React, {useState} from "react";
import PropTypes from "prop-types";
import {useTranslation} from "react-i18next";
import {
    TIME_IDE_BAND_4_HOUR,
    TIME_IDE_BAND_5_HOUR,
    TIME_IDE_BAND_6_HOUR,
    TIME_IDE_BAND_7_HOUR
} from "../../../../constants/simulationFields";
import makeStyles from "@material-ui/core/styles/makeStyles";
import cn from "classnames";
import Typography from "@material-ui/core/Typography";
import useEditionStyles from "../../../Simulation/Shared/useEditionStyles";
import Field from "../../../Simulation/Edition/Field/Field";

const useStyles = makeStyles(theme => ({
    subtitle: {
        display: "block",
        textAlign: "center",
        paddingBottom: "15px",
    },
    fields: {
        display: "flex",
        width: "356px",
        justifyContent: "space-around",
    },
    field: {
        flexBasis: "81px"
    },
    caption: {
        color: theme.palette.text.disabled,
        marginTop: '-25px',
        marginBottom: '10px',
        textAlign: 'center'
    },
    totalHours: {
        textAlign: 'center'
    }
}));

const NurseTime = ({timeIDEBand4, timeIDEBand5, timeIDEBand6, timeIDEBand7, handleSave}) => {
    const editionClasses = useEditionStyles();
    const {t} = useTranslation();
    const classes = useStyles();

    return (
        <>
            <Typography variant="h6" className={classes.subtitle}>{t('simulation.fields.time-ide-band.label')}</Typography>
            <div className={cn(editionClasses.fieldContainer, classes.fields)}>
                <Field
                    slug={TIME_IDE_BAND_4_HOUR}
                    defaultValue={timeIDEBand4}
                    label={t(`simulation.fields.${TIME_IDE_BAND_4_HOUR}.label`)}
                    onBlur={handleSave}
                    className={classes.field}
                    size="small"
                    type="float"
                />
                <Field
                    slug={TIME_IDE_BAND_5_HOUR}
                    defaultValue={timeIDEBand5}
                    label={t(`simulation.fields.${TIME_IDE_BAND_5_HOUR}.label`)}
                    onBlur={handleSave}
                    className={classes.field}
                    size="small"
                    type="float"
                />
                <Field
                    slug={TIME_IDE_BAND_6_HOUR}
                    defaultValue={timeIDEBand6}
                    label={t(`simulation.fields.${TIME_IDE_BAND_6_HOUR}.label`)}
                    onBlur={handleSave}
                    className={classes.field}
                    size="small"
                    type="float"
                />
                <Field
                    slug={TIME_IDE_BAND_7_HOUR}
                    defaultValue={timeIDEBand7}
                    label={t(`simulation.fields.${TIME_IDE_BAND_7_HOUR}.label`)}
                    onBlur={handleSave}
                    className={classes.field}
                    size="small"
                    type="float"
                />
            </div>
            <div className={classes.caption}>
                <Typography variant="caption">
                    {t(`simulation.fields.time-ide-band.helper`)}
                </Typography>
            </div>
            <div className={classes.totalHours}>
                <Typography>{timeIDEBand4 + timeIDEBand5 + timeIDEBand6 + timeIDEBand7} {t(`simulation.fields.time-ide-band-total.label`)}</Typography>
            </div>
        </>
    );
};

NurseTime.propTypes = {
    timeIDEBand4: PropTypes.number.isRequired,
    timeIDEBand5: PropTypes.number.isRequired,
    timeIDEBand6: PropTypes.number.isRequired,
    timeIDEBand7: PropTypes.number.isRequired,
    handleSave: PropTypes.func.isRequired
};

NurseTime.defaultProps = {};

export default NurseTime;