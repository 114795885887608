import {
    CENTRE_EDIT_IS_CLIENT,
    CENTRE_EDIT_IS_CLIENT_ERROR,
    CENTRE_EDIT_IS_CLIENT_FULFILLED,
    STATIC_DATA_FETCH_ERROR,
    STATIC_DATA_FETCH_FULFILLED,
    STATIC_DATA_FETCH_PENDING
} from "../../constants/staticData";
import apiFetch from "../../fetch";

export const fetch = () => async (dispatch, getState) => {
    if(getState().staticData.pending || getState().staticData.fulfilled) return;

    dispatch({type: STATIC_DATA_FETCH_PENDING});
    try {
        const response = await apiFetch({isPublic: false, dispatch}).get('static');
        dispatch({type: STATIC_DATA_FETCH_FULFILLED, payload: {data: response.data}});
    } catch(error) {
        console.error('error : ', error);
        dispatch({type: STATIC_DATA_FETCH_ERROR, payload: {error}});
    }
};

export const centreEditIsClient = (id, isClient) => async (dispatch, getState) => {
    dispatch({type: CENTRE_EDIT_IS_CLIENT, payload: {id, isClient}});
    const saveId = getState().staticData.edition.saveId;
    try {
        const response = await apiFetch({isPublic: false, dispatch}).post('centre/isClient', {id, isClient});
        if(response.data.error) {
            dispatch({type: CENTRE_EDIT_IS_CLIENT_ERROR, payload: {id, saveId, isClient, error: true}});
        } else {
            const centre = getState().staticData.centres[id];
            dispatch({type: CENTRE_EDIT_IS_CLIENT_FULFILLED, payload: {id, saveId, isClient, centre}});
        }
    } catch(error) {
        console.error('error : ', error);
        dispatch({type: CENTRE_EDIT_IS_CLIENT_ERROR, payload: {id, saveId, isClient, error: true}});
    }
};
