import {STATIC_DATA_FETCH_FULFILLED} from "../../constants/staticData";

export const INITIAL_STATE = {
    id: null,
    type: null,
    codes: []
};

export default function nhsReducer (state = INITIAL_STATE, {type, payload}) {
    switch(type) {
        case STATIC_DATA_FETCH_FULFILLED : {
            const {id, type, codes} = payload;
            return {...state,
                id,
                type,
                codes: (codes && codes[0] !== null) ? codes : []};
        }
    }
    return state;
};