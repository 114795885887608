import React, {useState} from "react";
import PropTypes from "prop-types";
import {useTranslation} from "react-i18next";
import makeStyles from "@material-ui/core/styles/makeStyles";
import ContrastedTextButton from "../../Material/ContrastedTextButton";
import {useDispatch, useSelector} from "react-redux";
import FaceOutlinedIcon from '@material-ui/icons/FaceOutlined';
import MonProfil from "../Administration/MonProfil/MonProfil";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Dialog from "@material-ui/core/Dialog";
import Typography from "@material-ui/core/Typography";
import DialogCloseButton from "../../Material/theme/DialogCloseButton";
import {updateUser} from "../../actions/user/userAction";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import ReferencesContent from "./ReferencesContent";
import {CLOSE_CI_REFERENCES, OPEN_CI_REFERENCES} from "../../constants/user";

const useStyles = makeStyles(theme => ({
    monProfilDialog: {
        borderRadius: "24px"
    },
    btn: {
        textTransform: 'none'
    }
}));

const References = () => {
    const classes = useStyles();
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const open = useSelector(state => state.authentication.ciReferences);

    const handleClick = () => {
        dispatch({type: OPEN_CI_REFERENCES});
    };

    const handleCancel = () => {
        dispatch({type: CLOSE_CI_REFERENCES});
    }

    return (
        <>
            <ContrastedTextButton
                startIcon={<InfoOutlinedIcon />}
                onClick={handleClick}
                size="large"
                className={classes.btn}
            >
                {t('header.piReferences')}
            </ContrastedTextButton>
            <Dialog
                open={open}
                onClose={handleCancel}
                aria-labelledby="administration-header-piReferences"
                fullWidth={true}
                maxWidth="md"
            >
                <DialogCloseButton handleClose={handleCancel} />
                <DialogContent>
                    <ReferencesContent handleCancel={handleCancel} />
                </DialogContent>
            </Dialog>
        </>
    );
}


References.propTypes = {

};

References.defaultProps = {

};

export default References;