import React from "react";
import PropTypes from "prop-types";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {
    TIME_IDE_BAND_4_HOUR,
    TIME_IDE_BAND_5_HOUR,
    TIME_IDE_BAND_6_HOUR,
    TIME_IDE_BAND_7_HOUR, TIME_MEDICAL_CONSULTANT, TIME_PHYSIO_PODIA
} from "../../../../constants/simulationFields";
import {setValue} from "../../../../actions/defaultValues/defaultValuesAction";
import Paper from "../../../Simulation/Shared/Paper";
import makeStyles from "@material-ui/core/styles/makeStyles";
import HourlyPlanning from "./HourlyPlanning";
import NurseTime from "./NurseTime";
import {Divider} from "@material-ui/core";
import OtherTime from "./OtherTime";


const useStyles = makeStyles(theme => ({
    divider: {
        width: 'calc(100% + 80px)',
        marginLeft: '-40px',
        marginTop: '18px',
        marginBottom: '29px',
    }
}));

const StaffManagement = () => {
    const classes = useStyles();
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const {value: band4} = useSelector(state => state.defaultValues.fields?.[TIME_IDE_BAND_4_HOUR]);
    const {value: band5} = useSelector(state => state.defaultValues.fields?.[TIME_IDE_BAND_5_HOUR]);
    const {value: band6} = useSelector(state => state.defaultValues.fields?.[TIME_IDE_BAND_6_HOUR]);
    const {value: band7} = useSelector(state => state.defaultValues.fields?.[TIME_IDE_BAND_7_HOUR]);


    const {value: medicalConsultant} = useSelector(state => state.defaultValues.fields?.[TIME_MEDICAL_CONSULTANT]);
    const {value: physioPodia} = useSelector(state => state.defaultValues.fields?.[TIME_PHYSIO_PODIA]);

    const handleSave = (slug, value) => {
        dispatch(setValue(slug, value));
    }

    return (
        <Paper title={t('simulation.staffManagement')}>
            <NurseTime
                handleSave={handleSave}
                timeIDEBand4={band4}
                timeIDEBand5={band5}
                timeIDEBand6={band6}
                timeIDEBand7={band7}
            />
            <Divider className={classes.divider} />
            <OtherTime
                handleSave={handleSave}
                timeMedicalConsultant={medicalConsultant}
                timPhysioPodia={physioPodia}
            />
            <Divider className={classes.divider} />
            <HourlyPlanning />
        </Paper>
    );
};

StaffManagement.propTypes = {};

StaffManagement.defaultProps = {};

export default StaffManagement;