import React from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Typography from "@material-ui/core/Typography";
import {useTranslation} from "react-i18next";

const useStyles = makeStyles(theme => ({
    label: {
        color: theme.palette.text.contrastText,
        marginLeft: '25px'
    }
}));

const HeaderLabel = () => {
    const classes = useStyles();
    const {t} = useTranslation();

    return (
        <Typography className={classes.label}>{t('header.label')}</Typography>
    );
};

HeaderLabel.propTypes = {

};

HeaderLabel.defaultProps = {

};

export default HeaderLabel;