import React, {useEffect, useState} from "react";
import PropTypes from "prop-types";
import Header from "../components/Header/Header";
import {fetch} from "../actions/staticData/staticDataAction";
import {useDispatch, useSelector} from "react-redux";
import {useHistory} from "react-router";
import useRouter from "../hooks/router/useRouter";
import {ERROR_PAGE} from "../constants/router";
import makeStyles from "@material-ui/core/styles/makeStyles";
import sizes from "../Material/theme/sizes";
import DefaultValuesProvider from "../providers/DefaultValuesProvider";
import SharedInformation from "../components/Footer/SharedInformation";

const useStyles = makeStyles(theme => ({
    pageContainer: {
        position: 'relative',
        zIndex: 1
    },
    spacer: {
        height: `${sizes.headerHeight}px`
    }
}));

const ConectedLayout = ({children, withHeaders=true}) => {
    const classes = useStyles();
    const history = useHistory();
    const getUrl = useRouter();
    const pending = useSelector(state => state.staticData.pending);
    const error = useSelector(state => state.staticData.error);
    const fulfilled = useSelector(state => state.staticData.fulfilled);
    const dispatch = useDispatch();
    const [mounted, setMounted] = useState(false);

    useEffect(() => {
        dispatch(fetch());
        setMounted(true);
    }, []);
    useEffect(() => {
        if(error && mounted) {
            history.push(getUrl({page: ERROR_PAGE}))
        }
    }, [error]);

    if(pending || error || !fulfilled) return null;

    return (
        <DefaultValuesProvider>
            { withHeaders && <Header />}
            <div className={classes.spacer} />
            <div className={classes.pageContainer}>
                {children}
            </div>
            <SharedInformation />
        </DefaultValuesProvider>
    );
};

ConectedLayout.propTypes = {
    children: PropTypes.node
};

ConectedLayout.defaultProps = {
    children: null
};

export default ConectedLayout;