import {
    OTHER_INCOME,
    COEFF_GEOGRAPHIQUE,
    CONSUMABLE_PER_DAY,
    COST_OTHER,
    DEPENSE_IDE,
    COST_MEDICAL_CONSULTANT,
    DEPENSE_STRUCTURE,
    DAY_AM_TIME,
    DAY_AM_BED,
    DAY_PM_TIME,
    DAY_PM_BED,
    DAYS,
    CONSUMABLE_COST,
    AMOUNT_NHS,
    ANNUAL_STAY,
    SERVICE_CONSOMMATION_PAR_ZONE,
    SERVICE_PART_SEJOUR_DOULOUREUX_ANNUEL,
    SERVICE_SEJOUR_TOTAL_ANNUEL,
    SERVICES,
    TIME_IDE_STAY_HOUR,
    TIME_BED_STAY_HOUR,
    TIME_IDE_BAND_4_HOUR,
    COST_IDE_BAND_4_HOUR,
    TIME_IDE_BAND_5_HOUR,
    COST_IDE_BAND_5_HOUR,
    TIME_IDE_BAND_6_HOUR,
    TIME_IDE_BAND_7_HOUR,
    COST_IDE_BAND_6_HOUR,
    COST_IDE_BAND_7_HOUR,
    TIME_MEDICAL_CONSULTANT,
    TIME_PHYSIO_PODIA,
    COST_PHYSIO_PODIA,
    CONSUMABLE_PROPORTION, DAY_PM_TIME_BEGIN, DAY_AM_TIME_BEGIN, AMOUNT_NHS_TYPE_LIST, AMOUNT_NHS_TYPE
} from "../constants/simulationFields";
import {useSelector} from "react-redux";
import {
    PARAM_OPTIMISATION_PLANNING,
    PARAM_OPTIMISATION_SERVICE
} from "../constants/optimisation";
import {
    PARAM_STATUT_ETAB_PRIVE,
    PARAM_TYPE_GEO_HD,
    QUERY_PARAM_ID_HD,
    QUERY_PARAM_ID_REG,
    QUERY_PARAM_IS_SDC,
    QUERY_PARAM_STATUS_CLIENT
} from "../constants/map";
import numberFormatter from "./numberFormater";

export const convertValToTime = (val, hasSign) => {
    let sign = (val >= 0) ? 1 : -1;
    val *= sign;
    const hour = Math.floor(val);
    let decpart = val - hour;
    const min = 1 / 60;
    decpart = min * Math.round(decpart / min);
    let minute = `${Math.floor(decpart * 60)}`;
    if (minute.length < 2) {
        minute = `0${minute}`;
    }
    sign = sign === 1 ? '+' : '-';
    if ( ! hasSign) {
        sign = '';
    }
    return `${sign+hour}:${minute}`;
};

export const formatWithSign = val => {
    if (val < 0) {
        return `-${val}`;
    }
    return `+${val}`;
};

export const formatNumber = (num, isFloat = false) => {
    let formatedNum = `${num}`;
    formatedNum = formatedNum.replace(',', '.');
    // formatedNum = formatedNum.replace(/[^0-9.]/, '');
    formatedNum = isFloat
        ? Number(parseFloat(formatedNum, 10).toFixed(2))
        : parseInt(formatedNum, 10)
    ;
    return formatedNum;
};

const getDayAmStay = (values, day, valueProp = 'mainValue') => {
    const timeBedStayHour = values[TIME_BED_STAY_HOUR][valueProp] * 60;
    return timeBedStayHour === 0 ? 0 : Math.ceil(values[`${day}${DAY_AM_TIME}`][valueProp] / timeBedStayHour);
};

const getDayPmStay = (values, day, valueProp = 'mainValue', dayAMStay = 0) => {
    const amTimeBegin = values[`${day}${DAY_AM_TIME_BEGIN}`][valueProp];
    let dayPMTime = values[`${day}${DAY_PM_TIME}`][valueProp];
    const pmTimeBegin = values[`${day}${DAY_PM_TIME_BEGIN}`][valueProp];
    const timeBedStayHour = values[TIME_BED_STAY_HOUR][valueProp] * 60;
    dayPMTime = (amTimeBegin + dayAMStay * timeBedStayHour <= pmTimeBegin)
        ? dayPMTime
        : dayPMTime + (pmTimeBegin - (amTimeBegin + dayAMStay * timeBedStayHour));
    return timeBedStayHour === 0 ? 0 : Math.ceil(dayPMTime / timeBedStayHour);
};

const getDayStay = (values, day, valueProp = 'mainValue') => {
    const dayAMStay = getDayAmStay(values, day, valueProp);
    const nbAmBed = values[`${day}${DAY_AM_BED}`][valueProp];
    const nbPmBed = values[`${day}${DAY_PM_BED}`][valueProp];
    return (dayAMStay * nbAmBed
        + (
            nbAmBed >= nbPmBed
                ? Math.ceil(getDayPmStay(values, day, valueProp, dayAMStay)) * nbPmBed
                : (Math.ceil(getDayPmStay(values, day, valueProp, dayAMStay)) * nbAmBed
                    + Math.ceil(getDayPmStay(values, day, valueProp)) * (nbPmBed - nbAmBed))
        )
    );
};

const getServicePatientDnpAnnuel = (values, service, valueProp = 'mainValue') => {
    return Math.round(values[`${service}${SERVICE_SEJOUR_TOTAL_ANNUEL}`][valueProp] *
        values[`${service}${SERVICE_PART_SEJOUR_DOULOUREUX_ANNUEL}`][valueProp]);
}

export const getAnnualStay = (values, optimisation = null, valueProp = 'mainValue') => {
    if(optimisation === PARAM_OPTIMISATION_PLANNING) {
        return (DAYS.reduce((accu, day) =>
            accu + getDayStay(values, day, valueProp),
            0
        ) * 52);
    }

    if(optimisation === PARAM_OPTIMISATION_SERVICE) {
        return (SERVICES.reduce((accu, service) =>
            accu + getServicePatientDnpAnnuel(values, service, valueProp),
            0
        ));
    }

    return values[ANNUAL_STAY][valueProp];
};

export const getConsumableStay = (values, optimisation = null, valueProp = 'mainValue') => {
    if(optimisation !== PARAM_OPTIMISATION_SERVICE) {
        return values[CONSUMABLE_PER_DAY][valueProp];
    }
    const sejour = getAnnualStay(values, optimisation, valueProp);
    return sejour === 0 ? 0 : (
        SERVICES.reduce((accu, service) =>
            accu + (
                getServicePatientDnpAnnuel(values, service, valueProp) *
                values[`${service}${SERVICE_CONSOMMATION_PAR_ZONE}`][valueProp]
            ),
            0
        ) / sejour
    );
};

export const getCostNurseStay = (values, valueProp = 'mainValue') => {
    return (
        values[TIME_IDE_BAND_4_HOUR][valueProp] * values[COST_IDE_BAND_4_HOUR][valueProp] +
        values[TIME_IDE_BAND_5_HOUR][valueProp] * values[COST_IDE_BAND_5_HOUR][valueProp] +
        values[TIME_IDE_BAND_6_HOUR][valueProp] * values[COST_IDE_BAND_6_HOUR][valueProp] +
        values[TIME_IDE_BAND_7_HOUR][valueProp] * values[COST_IDE_BAND_7_HOUR][valueProp]
    );
};

export const getCostNurseTotal = (values, optimisation = null, valueProp = 'mainValue') => {
    return (getAnnualStay(values, optimisation, valueProp) * getCostNurseStay(values, valueProp));
};

export const getCostMedicalConsultantStay = (values, valueProp = 'mainValue') => {
    return (values[COST_MEDICAL_CONSULTANT][valueProp] * values[TIME_MEDICAL_CONSULTANT][valueProp]);
}

export const getCostMedicalConsultantTotal = (values, optimisation = null, valueProp = 'mainValue') => {
    return (getAnnualStay(values, optimisation, valueProp) * getCostMedicalConsultantStay(values, valueProp));
};

export const getCostPhysioPodiaStay = (values, valueProp = 'mainValue') => {
    return (values[COST_PHYSIO_PODIA][valueProp] * values[TIME_PHYSIO_PODIA][valueProp]);
}

export const getCostPhysioPodiaTotal = (values, optimisation = null, valueProp = 'mainValue') => {
    return (getAnnualStay(values, optimisation, valueProp) * getCostPhysioPodiaStay(values, valueProp));
}

export const getCostOtherTotal = (values, optimisation = null, valueProp = 'mainValue') => {
    return (getAnnualStay(values, optimisation, valueProp) * values[COST_OTHER][valueProp]);
};

export const getStaffCostTotal = (values, optimisation = null, valueProp = 'mainValue') => {
    return (
        getCostMedicalConsultantTotal(values, optimisation, valueProp) +
        getCostNurseTotal(values, optimisation, valueProp) +
        getCostPhysioPodiaTotal(values, optimisation, valueProp) +
        getCostOtherTotal(values, optimisation, valueProp)
    );
};

export const getStaffCostStay = (values, valueProp = 'mainValue') => {
    return (
        getCostMedicalConsultantStay(values, valueProp) +
        getCostNurseStay(values, valueProp) +
        getCostPhysioPodiaStay(values, valueProp) +
        values[COST_OTHER][valueProp]
    );
};

export const getQT = (values, valueProp = 'mainValue') => {
    return getConsumableStay(values, null, valueProp) * values[CONSUMABLE_COST][valueProp];
}

export const getConsumableCostStay = (values, optimisation = null, valueProp = 'mainValue') => {
    return (getQT(values, valueProp) * (1 - (values[CONSUMABLE_PROPORTION][valueProp] / 100)));
};

export const getConsumableCostTotal = (values, optimisation = null, valueProp = 'mainValue') => {
    return (getConsumableCostStay(values, optimisation, valueProp) * getAnnualStay(values, optimisation, valueProp));
};

export const getNHSTariff = (values, valueProp = 'mainValue', withQT = false) => {
    const total = values[AMOUNT_NHS][valueProp] + values[OTHER_INCOME][valueProp];
    if (!withQT) {
        return total;
    }
    return total + getQT(values, valueProp);
};

export const getRecipeTotal = (values, optimisation = null, valueProp = 'mainValue', withQT = false) => {
    return (getNHSTariff(values, valueProp, withQT) * getAnnualStay(values, optimisation, valueProp));
};

export const getOperatingMarginStay = (values, optimisation = null, valueProp = 'mainValue', withQT = false) => {
    return (
        getNHSTariff(values, valueProp, withQT) -
        getConsumableCostStay(values, optimisation, valueProp) -
        getStaffCostStay(values, valueProp)
    );
};

export const getOperatingMarginTotal = (values, optimisation = null, valueProp = 'mainValue', withQT = false) => {
    return (getOperatingMarginStay(values, optimisation, valueProp, withQT) * getAnnualStay(values, optimisation, valueProp));
};

export const getGrossMarginStay = (values, optimisation = null, valueProp = 'mainValue', withQT = false) => {
    return (
        getNHSTariff(values, valueProp, withQT) -
        getConsumableCostStay(values, optimisation, valueProp)
    );
};

export const getGrossMarginTotal = (values, optimisation = null, valueProp = 'mainValue', withQT = false) => {
    return (getGrossMarginStay(values, optimisation, valueProp, withQT) * getAnnualStay(values, optimisation, valueProp));
};

const getTimeBedStayWeek = (values, optimisation = null, valueProp = 'mainValue') => {
    return ((
        getAnnualStay(values, optimisation, valueProp) *
        values[TIME_BED_STAY_HOUR][valueProp]
    ) / 52);
};

const getTimeIdeStayWeek = (values, optimisation = null, valueProp = 'mainValue') => {
    return ((
        getAnnualStay(values, optimisation, valueProp) *
        (
            values[TIME_IDE_BAND_4_HOUR][valueProp] +
            values[TIME_IDE_BAND_5_HOUR][valueProp] +
            values[TIME_IDE_BAND_6_HOUR][valueProp] +
            values[TIME_IDE_BAND_7_HOUR][valueProp] +
            values[TIME_MEDICAL_CONSULTANT][valueProp] +
            values[TIME_PHYSIO_PODIA][valueProp]
        )
    ) / 52);
};

/*

    Résultat Actuel

    (
        panel édition : Sejours annuel *
        Panel édition : Temps fauteuil lit alloué à la pose
        / 52
    )
    /
    (
        (Panel édition : plage horaire lundi matin * Panel édition : Fauteuils lit alloués à la pose lundi matin) + (Panel édition : plage horaire lundi matin * Panel édition : Fauteuils lit alloués à la pose lundi matin) +
        (Panel édition : plage horaire mardi matin * Panel édition : Fauteuils lit alloués à la pose mardi matin) + (Panel édition : plage horaire mardi matin * Panel édition : Fauteuils lit alloués à la pose mardi matin) +
        (Panel édition : plage horaire mercredi matin * Panel édition : Fauteuils lit alloués à la pose mercredi matin) + (Panel édition : plage horaire mercredi matin * Panel édition : Fauteuils lit alloués à la pose mercredi matin) +
        ...
    )

    Optimisation simplifié

    (
        panel optimisation simplifié : Sejours annuel *
        Panel optimisation planning : Temps fauteuil lit alloué à la pose
        / 52
    )
    /
    (
        (Panel optimisation planning : plage horaire lundi matin * Panel optimisation planning : Fauteuils lit alloués à la pose lundi matin) + (Panel optimisation planning : plage horaire lundi matin * Panel optimisation planning : Fauteuils lit alloués à la pose lundi matin) +
        (Panel optimisation planning : plage horaire mardi matin * Panel optimisation planning : Fauteuils lit alloués à la pose mardi matin) + (Panel optimisation planning : plage horaire mardi matin * Panel optimisation planning : Fauteuils lit alloués à la pose mardi matin) +
        (Panel optimisation planning : plage horaire mercredi matin * Panel optimisation planning : Fauteuils lit alloués à la pose mercredi matin) + (Panel optimisation planning : plage horaire mercredi matin * Panel optimisation planning : Fauteuils lit alloués à la pose mercredi matin) +
        ...
    )

    Optimisation service
    (
        (
            Panel optimisation service : Séjours annuels Oncologie * Panel optimisation service : % de DNP Oncologie / 100 +
            Panel optimisation service : Séjours annuels Soins Palliatifs * Panel optimisation service : % de DNP Soins Palliatifs / 100 +
            Panel optimisation service : Séjours annuels Chirurgie Générale * Panel optimisation service : % de DNP Chirurgie Générale / 100 +
            ...
        ) *
        Panel optimisation planning : Temps fauteuil lit alloué à la pose
        / 52
    )
    /
    (
        (Panel optimisation planning : plage horaire lundi matin * Panel optimisation planning : Fauteuils lit alloués à la pose lundi matin) + (Panel optimisation planning : plage horaire lundi matin * Panel optimisation planning : Fauteuils lit alloués à la pose lundi matin) +
        (Panel optimisation planning : plage horaire mardi matin * Panel optimisation planning : Fauteuils lit alloués à la pose mardi matin) + (Panel optimisation planning : plage horaire mardi matin * Panel optimisation planning : Fauteuils lit alloués à la pose mardi matin) +
        (Panel optimisation planning : plage horaire mercredi matin * Panel optimisation planning : Fauteuils lit alloués à la pose mercredi matin) + (Panel optimisation planning : plage horaire mercredi matin * Panel optimisation planning : Fauteuils lit alloués à la pose mercredi matin) +
        ...
    )

    Optimisation planning
    (
        (
            (
                (Panel optimisation planning : plage horaire lundi matin * Panel optimisation planning : Fauteuils lit alloués à la pose lundi matin) + (Panel optimisation planning : plage horaire lundi matin * Panel optimisation planning : Fauteuils lit alloués à la pose lundi matin) +
                (Panel optimisation planning : plage horaire mardi matin * Panel optimisation planning : Fauteuils lit alloués à la pose mardi matin) + (Panel optimisation planning : plage horaire mardi matin * Panel optimisation planning : Fauteuils lit alloués à la pose mardi matin)  +
                (Panel optimisation planning : plage horaire mercredi matin * Panel optimisation planning : Fauteuils lit alloués à la pose mercredi matin) + (Panel optimisation planning : plage horaire mercredi matin * Panel optimisation planning : Fauteuils lit alloués à la pose mercredi matin)  +
                ...
            ) / Panel optimisation planning : Temps fauteuil lit alloué à la pose
            * Panel optimisation planning : Temps fauteuil lit alloué à la pose
        ) * 52
    ) *
        Panel optimisation planning : Temps fauteuil lit alloué à la pose / 52
    )
    /
    (
        (Panel optimisation planning : plage horaire lundi matin * Panel optimisation planning : Fauteuils lit alloués à la pose lundi matin) + (Panel optimisation planning : plage horaire lundi matin * Panel optimisation planning : Fauteuils lit alloués à la pose lundi matin) +
        (Panel optimisation planning : plage horaire mardi matin * Panel optimisation planning : Fauteuils lit alloués à la pose mardi matin) + (Panel optimisation planning : plage horaire mardi matin * Panel optimisation planning : Fauteuils lit alloués à la pose mardi matin) +
        (Panel optimisation planning : plage horaire mercredi matin * Panel optimisation planning : Fauteuils lit alloués à la pose mercredi matin) + (Panel optimisation planning : plage horaire mercredi matin * Panel optimisation planning : Fauteuils lit alloués à la pose mercredi matin) +
        ...
    )
    sum jours / lit * 52 * lit / 52 / sum jours

    ((((12/1.5)*52)*1.5)/52)/12

*/
const getRateOccupationBed = (values, optimisation = null, valueProp = 'mainValue') => {
    const sum = DAYS.reduce((accu, day) =>
        accu + (
            values[`${day}${DAY_AM_TIME}`][valueProp] *
            values[`${day}${DAY_AM_BED}`][valueProp]
        ) + (
            values[`${day}${DAY_PM_TIME}`][valueProp] *
            values[`${day}${DAY_PM_BED}`][valueProp]
        ),
        0
    );
    return sum === 0
        ? 0
        : (getTimeBedStayWeek(values, optimisation, valueProp) / (sum/60))
        ;
};

export const useAnnualStay = (isBenchmark, optimisation = null) => {
    const valueProp = !isBenchmark ? 'mainValue' : 'benchmarkValue';
    const values = useSelector(state => state.simulation.values);

    return getAnnualStay(values, optimisation, valueProp);
}

export const useDayStay = (isBenchmark, day) => {
    const valueProp = !isBenchmark ? 'mainValue' : 'benchmarkValue';
    const values= useSelector(state => state.simulation.values);

    return getDayStay(values, day, valueProp);
}

export const useServicePatientDnpAnnuel = (isBenchmark, service) => {
    const valueProp = !isBenchmark ? 'mainValue' : 'benchmarkValue';
    const values = useSelector(state => state.simulation.values);

    return getServicePatientDnpAnnuel(values, service, valueProp);
}

export const useConsommableMoyenSejour = (isBenchmark, optimisation = null) => {
    const valueProp = !isBenchmark ? 'mainValue' : 'benchmarkValue';
    const values = useSelector(state => state.simulation.values);

    return getConsumableStay(values, optimisation, valueProp);
}

export const useStaffCostTotal = (isBenchmark, optimisation = null) => {
    const valueProp = !isBenchmark ? 'mainValue' : 'benchmarkValue';
    const values = useSelector(state => state.simulation.values);

    return getStaffCostTotal(values, optimisation, valueProp);
}

export const useCoutConsommableTotal = (isBenchmark, optimisation = null) => {
    const valueProp = !isBenchmark ? 'mainValue' : 'benchmarkValue';
    const values = useSelector(state => state.simulation.values);

    return getConsumableCostTotal(values, optimisation, valueProp);
}

export const useRecipeStay = isBenchmark => {
    const valueProp = !isBenchmark ? 'mainValue' : 'benchmarkValue';
    const values = useSelector(state => state.simulation.values);

    return getNHSTariff(values, valueProp);
}

export const useTempsLitSejourSemaine = (isBenchmark, optimisation = null) => {
    const valueProp = !isBenchmark ? 'mainValue' : 'benchmarkValue';
    const values = useSelector(state => state.simulation.values);

    return getTimeBedStayWeek(values, optimisation, valueProp);
}

export const useStaffTimeStayWeek = (isBenchmark, optimisation = null) => {
    const valueProp = !isBenchmark ? 'mainValue' : 'benchmarkValue';
    const values = useSelector(state => state.simulation.values);

    return getTimeIdeStayWeek(values, optimisation, valueProp);
}
export const useRateOccupationBed = (isBenchmark, optimisation = null) => {
    const valueProp = !isBenchmark ? 'mainValue' : 'benchmarkValue';
    const values = useSelector(state => state.simulation.values);
    return getRateOccupationBed(values, optimisation, valueProp) * 100;
}

export const getDiffPercent = (amount, avg, format = false) => {
    let diff = numberFormatter.getValue(Math.round(((amount - avg) / avg) * 100), true);
    if (format) {
        diff = numberFormatter.getValue(diff, true);
    }
    return diff > 0 ? `+${diff}` : `${diff}`;
};

export const getDiff = (value, benchValue, format = false) => {
    let diff = benchValue - value;
    if (format) {
        diff = numberFormatter.getValue(diff, true);
    }
    return diff > 0 ? `+${diff}` : `${diff}`;
};

export const useGetNbCentresFromGeo = () => {
    const centres = useSelector(state => state.staticData.centres);

    return (geoType, id) => {
        const geoIdProp = geoType === PARAM_TYPE_GEO_HD ? QUERY_PARAM_ID_HD : QUERY_PARAM_ID_REG;
        return Object.keys(centres).filter(e => {
            return centres[e][geoIdProp] === id;
        }).length;
    };
};

export const useGetNbPrivateCentresFromGeo = () => {
    const centres = useSelector(state => state.staticData.centres);
    return (geoType, id, checkIsSdc) => {
        const geoIdProp = geoType === PARAM_TYPE_GEO_HD ? QUERY_PARAM_ID_HD : QUERY_PARAM_ID_REG;
        return Object.keys(centres).filter(e => {
            if (checkIsSdc && ! centres[e][QUERY_PARAM_IS_SDC]) {
                return false;
            }
            return centres[e][geoIdProp] === id && centres[e][QUERY_PARAM_STATUS_CLIENT] === PARAM_STATUT_ETAB_PRIVE;
        }).length;
    };
};

export const useGetNbSDCFromGeo = () => {
    const centres = useSelector(state => state.staticData.centres);

    return (geoType, geoId) => {
        const geoIdProp = geoType === PARAM_TYPE_GEO_HD ? QUERY_PARAM_ID_HD : QUERY_PARAM_ID_REG;
        return Object.keys(centres).filter(e => {
            return centres[e].isSdc === true && centres[e][geoIdProp] === geoId;
        }).length;
    };
};

export const useGetAverageSDC = () => {
    const centres = useSelector(state => state.staticData.centres);
    const healthDivisions = useSelector(state => state.staticData.healthDivisions);
    const regions = useSelector(state => state.staticData.regions);
    return (geoType) => {
        const geos = geoType === PARAM_TYPE_GEO_HD ? healthDivisions : regions;
        const total = Object.values(centres).reduce((t, {isSdc}) => t + isSdc, 0);
        return Math.round(total / Object.keys(geos).length);
    };
};

export const useGetAverageCentres = () => {
    const centres = useSelector(state => state.staticData.centres);
    const healthDivisions = useSelector(state => state.staticData.healthDivisions);
    const regions = useSelector(state => state.staticData.regions);
    return (geoType) => {
        const geos = geoType === PARAM_TYPE_GEO_HD ? healthDivisions : regions;
        const avg = Object.keys(centres).length / Object.keys(geos).length;
        return Math.round(avg);
    };
};

export const useGetAveragePopulation = () => {
    const healthDivisions = useSelector(state => state.staticData.healthDivisions);
    const regions = useSelector(state => state.staticData.regions);
    return (geoType) => {
        const geos = geoType === PARAM_TYPE_GEO_HD ? healthDivisions : regions;
        const total = Object.values(geos).reduce((t, {dnp}) => t + dnp, 0);
        return Math.round(total / Object.keys(geos).length);
    };
};

export const useGetAveragePatients = () => {
    const healthDivisions = useSelector(state => state.staticData.healthDivisions);
    const regions = useSelector(state => state.staticData.regions);
    return (geoType) => {
        const geos = geoType === PARAM_TYPE_GEO_HD ? healthDivisions : regions;
        const total = Object.values(geos).reduce((t, {patients}) => t + patients, 0);
        return Math.round(total / Object.keys(geos).length);
    };
};

