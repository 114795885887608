import {
    DEFAULT_VALUE_ADD_NHS,
    DEFAULT_VALUES_FETCH_FULFILLED,
    DEFAULT_VALUES_SET,
} from "../../constants/defaultValues";
import defaultValueNHSReducer, {INITIAL_STATE as NHS_INITIAL_STATE} from "./defaultValueNHSReducer";
import {arrayAdd, arrayUpdate} from "../../utils/immutableArray";

export const INITIAL_STATE = {
    slug: null,
    value: [],
    error: false
}

export default function defaultValuesNHSReducer (state = INITIAL_STATE, {type, payload}) {
    switch (type) {
        case DEFAULT_VALUES_FETCH_FULFILLED: {
            const {slug, value} = payload;

            return {
                ...state,
                slug,
                value: value.map(({type: nhsType, year, status, value, code, percent}) => defaultValueNHSReducer(NHS_INITIAL_STATE, {type, payload: {year, type: nhsType, status, value, code, percent}}))
            }
        }
        case DEFAULT_VALUES_SET: {
            const {value, error} = payload;
            const {code, year, type: nhsType} = value;
            const index = state.value.findIndex(nhs => nhs.code === code && nhs.year === year && nhs.type === nhsType);

            if (Number.isNaN(index)) return state;
            const newValue = arrayUpdate(state.value, index, defaultValueNHSReducer(state.value[index], {type, payload: {value}}));
            return {
                ...state,
                value: newValue,
                error: error || !!newValue.find((val) => val.error === true)
            }
        }
        case DEFAULT_VALUE_ADD_NHS: {
            const {value} = payload;
            let newValue = state.value;
            value.reverse().forEach((nhs) => {
                newValue = arrayAdd(newValue, 0, defaultValueNHSReducer(NHS_INITIAL_STATE, {type, payload: nhs}));
            })

            return {
                ...state,
                value: newValue,
            }
        }
    }
    return state;
}