export const EXPORT_PDF_PENDING   = 'EXPORT_PDF_PENDING';
export const EXPORT_PDF_FULFILLED = 'EXPORT_PDF_FULFILLED';
export const EXPORT_PDF_ERROR     = 'EXPORT_PDF_ERROR';

export const INITIAL_STATE = {
    pending: false,
    fulfilled: false,
    error: false,
}

export default function optimisationReducer(state = INITIAL_STATE, {type, payload}) {
    switch(type) {
        case EXPORT_PDF_PENDING: {
            return {
                ...state,
                pending: true,
                fulfilled: false,
                error: false,
            };
        }

        case EXPORT_PDF_FULFILLED: {
            return {
                ...state,
                pending: false,
                fulfilled: true,
                error: false,
            };
        }

        case EXPORT_PDF_ERROR: {
            return {
                ...state,
                pending: false,
                fulfilled: false,
                error: true,
            };
        }
    }

    return state;
}