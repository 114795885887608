import React from "react";
import PropTypes from "prop-types";
import makeStyles from "@material-ui/core/styles/makeStyles";
import {useDispatch, useSelector} from "react-redux";
import {
    DAY_AM_TIME,
    DAY_PM_TIME,
    DAY_AM_TIME_BEGIN,
    DAY_PM_TIME_BEGIN, DAY_AM_TIME_END, DAY_PM_TIME_END
} from "../../../../constants/simulationFields";
import PlanningTimes from "../../../Simulation/Edition/Planning/PlanningTimes";
import {setValue} from "../../../../actions/defaultValues/defaultValuesAction";
import useEditionStyles from "../../../Simulation/Shared/useEditionStyles";
import {useTranslation} from "react-i18next";
import InputLabel from "@material-ui/core/InputLabel";

const useStyles = makeStyles(theme => ({
    PlanningRowRoot: {
        position: 'relative',
        width: '300px',
        marginBottom: '28px',
        marginLeft: 'auto',
        marginRight: 'auto',
    },
    fieldContainer: {
        position: 'relative',
        width: '300px',
        marginLeft: 'auto',
        marginRight: 'auto',
    },
    margin: {
        marginBottom: "10px"
    },
    center: {
        marginLeft: "10px"
    },
    caption: {
        color: theme.palette.text.secondary
    },

    planningRoot: {
        border: `solid 1px ${theme.palette.divider}`,
    },
    head: {
        width: '100%',
        backgroundColor: `${theme.palette.grey[100]} !important`,
        textAlign: 'center'
    },
    disabled: {
        color: theme.palette.text.disabled,
    },
    planningHorrairesRoot: {
        display: 'flex',
        alignItems: 'center'
    },
    secondLib: {
        display: 'block',
        marginRight: '15px',
    },
    pm: {
        marginTop: '18px'
    },
}));


const HourlyPlanning = () => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const editionClasses = useEditionStyles();
    const {t} = useTranslation();
    const {value: amTimeBegin, error: amTimeBeginError} = useSelector(state => state.defaultValues.fields?.[DAY_AM_TIME_BEGIN]);
    const {value: amTime, error: amTimeError} = useSelector(state => state.defaultValues.fields?.[DAY_AM_TIME]);
    const {value: pmTimeBegin, error: pmTimeBeginError} = useSelector(state => state.defaultValues.fields?.[DAY_PM_TIME_BEGIN]);
    const {value: pmTime, error: pmTimeError} = useSelector(state => state.defaultValues.fields?.[DAY_PM_TIME]);
    const errors = {
        [DAY_AM_TIME_BEGIN]: amTimeBeginError,
        [`${DAY_AM_TIME}-end`]: amTimeError,
        [DAY_PM_TIME_BEGIN]: pmTimeBeginError,
        [`${DAY_PM_TIME}-end`]: pmTimeError,
    }

    const hourlyErrorChecker = (slug) => {
        return !errors[slug];
    }

    const handleSaveHourly = (halfDay, timeBegin, hourly, isBegin) => {
        const slugHorraireDebut = halfDay === 'am'
            ? DAY_AM_TIME_BEGIN
            : DAY_PM_TIME_BEGIN
        ;
        const slugHorraire = halfDay === 'am'
            ? DAY_AM_TIME
            : DAY_PM_TIME
        ;

        if (isBegin) {
            dispatch(setValue(slugHorraireDebut, timeBegin));
            dispatch(setValue(slugHorraire, hourly));
        } else {
            dispatch(setValue(slugHorraire, hourly));
            dispatch(setValue(slugHorraireDebut, timeBegin));
        }

        dispatch(setValue(halfDay !== 'am' ? DAY_AM_TIME_BEGIN : DAY_PM_TIME_BEGIN, halfDay !== 'am' ? amTimeBegin : pmTimeBegin));
        dispatch(setValue(halfDay !== 'am' ? DAY_AM_TIME : DAY_PM_TIME, halfDay !== 'am' ? amTime : pmTime));
    }

    return (
        <>
            <div className={classes.PlanningRowRoot}>
                <div className={classes.center}>
                    <InputLabel
                        htmlFor={DAY_AM_TIME_BEGIN}
                        error={!hourlyErrorChecker(DAY_AM_TIME_BEGIN) || !hourlyErrorChecker(`${DAY_AM_TIME}-fin`) }
                    >
                        {t('administration.fields.hourly.am')}
                    </InputLabel>
                    <PlanningTimes
                        day=""
                        nbHour={amTime}
                        beginTime={amTimeBegin}
                        onChange={handleSaveHourly}
                        errorChecker={hourlyErrorChecker}
                        showFirstLib={false}
                        classes={classes}
                    />
                </div>
            </div>
            <div className={classes.fieldContainer}>
                <div className={classes.center}>
                    <InputLabel
                        htmlFor={DAY_PM_TIME_BEGIN}
                        error={!hourlyErrorChecker(DAY_PM_TIME_BEGIN) || !hourlyErrorChecker(`${DAY_PM_TIME}-fin`) }
                    >
                        {t('administration.fields.hourly.pm')}
                    </InputLabel>
                    <PlanningTimes
                        day=""
                        halfDay="pm"
                        nbHour={pmTime}
                        beginTime={pmTimeBegin}
                        onChange={handleSaveHourly}
                        errorChecker={hourlyErrorChecker}
                        showFirstLib={false}
                        addMargin={false}
                        classes={classes}
                    />
                </div>
            </div>
        </>
    );
};

HourlyPlanning.propTypes = {
};

HourlyPlanning.defaultProps = {
};

export default HourlyPlanning;