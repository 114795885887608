import React from "react";
import PropTypes from "prop-types";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {
    TOTAL_BED,
    TIME_BED_STAY_HOUR
} from "../../../../constants/simulationFields";
import Field from "../../../Simulation/Edition/Field/Field";
import useEditionStyles from "../../../Simulation/Shared/useEditionStyles";
import {setValue} from "../../../../actions/defaultValues/defaultValuesAction";
import Paper from "../../../Simulation/Shared/Paper";
import makeStyles from "@material-ui/core/styles/makeStyles";


const useStyles = makeStyles(theme => ({
}));

const ResourceManagement = () => {
    const classes = useStyles();
    const editionClasses = useEditionStyles();
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const {value: totalBed, error: totalBedError} = useSelector(state => state.defaultValues.fields?.[TOTAL_BED]);
    const {value: timeBedStayHour, error: timeBedStayHourError} = useSelector(state => state.defaultValues.fields?.[TIME_BED_STAY_HOUR]);

    const errorChecker = () => {
        return !(totalBedError || timeBedStayHourError);
    }

    const handleSave = (slug, value) => {
        dispatch(setValue(slug, value));
    }

    return (
        <Paper title={t('simulation.resourceManagement')}>
            <div className={editionClasses.fieldContainer}>
                <Field
                    slug={TOTAL_BED}
                    defaultValue={totalBed}
                    label={t(`simulation.fields.${TOTAL_BED}.label`)}
                    onBlur={handleSave}
                    errorChecker={errorChecker}
                />
            </div>
            <div className={editionClasses.fieldContainer}>
                <Field
                    type="float"
                    slug={TIME_BED_STAY_HOUR}
                    defaultValue={timeBedStayHour}
                    label={t(`simulation.fields.${TIME_BED_STAY_HOUR}.label`)}
                    helper={t(`simulation.fields.${TIME_BED_STAY_HOUR}.helper`)}
                    onBlur={handleSave}
                    hasHelper={true}
                />
            </div>
        </Paper>
    );
};

ResourceManagement.propTypes = {};

ResourceManagement.defaultProps = {};

export default ResourceManagement;