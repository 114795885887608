import {
    ACTION_LOGIN_ERROR, ACTION_LOGIN_MDP, ACTION_LOGIN_MDP_ERROR, ACTION_LOGIN_MDP_SUCCES,
    ACTION_LOGIN_PENDING,
    ACTION_LOGIN_SUCCES,
    ACTION_LOGOUT
} from "../../constants/authentication";
import fetch from "../../fetch";
import {cleanJwtToken, setJwtToken} from "./localStorageAction";

export const performLogin = (response, dispatch) => {
    const {token, refresh_token: refreshToken} = response.data;
    setJwtToken(token, refreshToken);
    dispatch({type: ACTION_LOGIN_SUCCES, payload: {token}});
};

export const logout = () => {
    cleanJwtToken();
    return { type: ACTION_LOGOUT };
};

export const login = (email, password, successCallback = () => null, errorCallback = () => null) => async (dispatch, getState) => {
    if(getState().authentication.pending) return;

    dispatch({type: ACTION_LOGIN_PENDING, payload: {email}});
    try {
        const response = await fetch({isPublic: true}).post('login_check', {
            email,
            password
        });
        performLogin(response, dispatch);
        successCallback();
    } catch(error) {
        console.error(error);
        dispatch({type: ACTION_LOGIN_ERROR, payload: {email}});
        errorCallback();
    }
};

export const mdpOublie = (email, successCallback = () => null)  => async (dispatch, getState) => {
    if (!email) return;

    dispatch({type: ACTION_LOGIN_MDP});
    try {
        const response = await fetch({isPublic: true}).post('mdpOublie', {
            email,
        });
        dispatch({type: ACTION_LOGIN_MDP_SUCCES});
        successCallback();
    } catch(error) {
        dispatch({type: ACTION_LOGIN_MDP_ERROR});
        console.error(error);
    }
}