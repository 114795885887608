import {
    SIMULATION_FETCH_FULFILLED,
    SIMULATION_RENAME,
    SIMULATION_RENAME_ERROR,
    SIMULATION_RENAME_FULFILLED
} from "../../constants/simulation";
import editionReducer, {INITIAL_STATE as EDITION_INITIAL_STATE} from "../edition/editionReducer";
import {EDIT_VALUES_ERROR, EDIT_VALUES_FULFILLED, EDIT_VALUES_PENDING} from "../../constants/edition";

export const INITIAL_STATE = {
    idCentre: null,
    lab: null,
    edition: EDITION_INITIAL_STATE,
}

export default function simulationInfosReducer(state = INITIAL_STATE, {type, payload}) {
    switch (type) {
        case SIMULATION_FETCH_FULFILLED: {
            const {idCentre, lab} = payload;

            return {
                ...state,
                idCentre,
                lab
            }
        }
        case SIMULATION_RENAME: {
            const {lab, saveId} = payload;

            return {
                ...state,
                lab,
                edition: editionReducer(EDIT_VALUES_PENDING, {
                    saveId,
                    values: {
                        lab: [state.lab, lab]
                    }
                })
            }
        }
        case SIMULATION_RENAME_FULFILLED: {
            const {saveId, lab} = payload;

            return {
                ...state,
                lab,
                edition: editionReducer(EDIT_VALUES_FULFILLED, {saveId, lab})
            }
        }
        case SIMULATION_RENAME_ERROR: {
            const {saveId} = payload;
            const edition = editionReducer(EDIT_VALUES_ERROR, {saveId});
            return {
                ...state,
                lab: edition.errors[saveId].lab[0],
                edition
            }
        }
    }

    return state
}