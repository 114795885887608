import React from "react";
import cn from "classnames";
import PropTypes from "prop-types";
import {useTranslation} from "react-i18next";
import {decodeTime} from "../../../../managers/simulationManager";
import {DAY_AM_TIME, DAY_PM_TIME} from "../../../../constants/simulationFields";
import makeStyles from "@material-ui/core/styles/makeStyles";
import PlanningTimePicker from "./PlanningTimePicker";
import {setHours} from "date-fns";
import moment from "moment/moment";

const useStyles = makeStyles(theme => ({
}));

const PlanningTimes = ({
                               day,
                               nbHour,
                               beginTime,
                               halfDay,
                               onChange,
                               errorChecker,
                               showFirstLib,
                               helper,
                               addMargin,
                               classes
                           }) => {
    const {t} = useTranslation();

    const slugPrefix = halfDay === 'am'
        ? `${day}${DAY_AM_TIME}`
        : `${day}${DAY_PM_TIME}`
    ;
    const end = beginTime + nbHour;
    return (
        <div className={cn(classes.planningHorrairesRoot, {[classes.pm]: addMargin && halfDay === 'pm'})}>
            {showFirstLib && (
                <span className={classes.firstLib}>
                    {halfDay === 'am'
                        ? t('simulation.planningTable.from')
                        : t('simulation.planningTable.andFrom')
                    }
                </span>
            )}
            <div className={classes.fieldContainer}>
                <PlanningTimePicker
                    slug={`${slugPrefix}-begin`}
                    defaultTime={beginTime}
                    begin={beginTime}
                    end={end}
                    halfDay={halfDay}
                    onChange={onChange}
                    errorChecker={errorChecker}
                />
            </div>
            <span className={classes.secondLib}>
                {t('simulation.planningTable.a')}
            </span>
            <div className={classes.fieldContainer}>
                <PlanningTimePicker
                    slug={`${slugPrefix}-end`}
                    defaultTime={end}
                    begin={beginTime}
                    end={end}
                    halfDay={halfDay}
                    onChange={onChange}
                    errorChecker={errorChecker}
                />
            </div>
        </div>
    );
};

PlanningTimes.propTypes = {
    day: PropTypes.string.isRequired,
    halfDay: PropTypes.oneOf(['am', 'pm']),
    nbHour: PropTypes.number.isRequired,
    beginTime: PropTypes.number.isRequired,
    onChange: PropTypes.func,
    errorChecker: PropTypes.func,
    showFirstLib: PropTypes.bool,
    helper: PropTypes.string,
    addMargin: PropTypes.bool,
    classes: PropTypes.object.isRequired
};

PlanningTimes.defaultProps = {
    halfDay: 'am',
    onChange: () => {
    },
    errorChecker: () => true,
    showFirstLib: true,
    addMargin: true
};

export default PlanningTimes;