import React from "react";
import PropTypes from "prop-types";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {
    COST_OTHER,
    COST_MEDICAL_CONSULTANT,
    COST_IDE_BAND_4_HOUR,
    COST_IDE_BAND_5_HOUR,
    COST_IDE_BAND_6_HOUR,
    COST_IDE_BAND_7_HOUR, COST_PHYSIO_PODIA,
} from "../../../../constants/simulationFields";
import Field from "../../../Simulation/Edition/Field/Field";
import useEditionStyles from "../../../Simulation/Shared/useEditionStyles";
import {setValue} from "../../../../actions/defaultValues/defaultValuesAction";
import Paper from "../../../Simulation/Shared/Paper";
import makeStyles from "@material-ui/core/styles/makeStyles";
import NurseCosts from "./NurseCosts";
import {Divider} from "@material-ui/core";


const useStyles = makeStyles(theme => ({
    staffCostsRoot: {
    },
    divider: {
        width: 'calc(100% + 80px)',
        marginLeft: '-40px',
        marginTop: '18px',
        marginBottom: '29px',
    }
}));

const StaffCosts = () => {
    const classes = useStyles();
    const editionClasses = useEditionStyles();
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const values = useSelector(state => state.defaultValues.fields);

    const {value: costBand4, error: costBand4Error} = values[COST_IDE_BAND_4_HOUR];
    const {value: costBand5, error: costBand5Error} = values[COST_IDE_BAND_5_HOUR];
    const {value: costBand6, error: costBand6Error} = values[COST_IDE_BAND_6_HOUR];
    const {value: costBand7, error: costBand7Error} = values[COST_IDE_BAND_7_HOUR];

    const {value: costMedicalConsultant, error: costMedicalConsultantError} = useSelector(state => state.defaultValues.fields?.[COST_MEDICAL_CONSULTANT]);
    const {value: costPhysioPodia, error: costPhysioPodiaError} = useSelector(state => state.defaultValues.fields?.[COST_PHYSIO_PODIA]);
    const {value: costOther, error: costOtherError} = useSelector(state => state.defaultValues.fields?.[COST_OTHER]);
    const errors = {
    };

    const errorChecker = (slug) => {
        return !errors[slug];
    }

    const handleSave = (slug, value) => {
        dispatch(setValue(slug, parseFloat(value.replace('£', '').replaceAll(',', ''))));
    }

    return (
        <Paper title={t('simulation.staffCosts')}>
            <div className={editionClasses.fieldContainer}>
                <NurseCosts
                    handleSave={handleSave}
                    values={values}
                    costIDEBand4={costBand4}
                    costIDEBand5={costBand5}
                    costIDEBand6={costBand6}
                    costIDEBand7={costBand7}
                />
            </div>
            <Divider className={classes.divider} />
            <div className={editionClasses.fieldContainer}>
                <Field
                    type="currency"
                    slug={COST_MEDICAL_CONSULTANT}
                    defaultValue={costMedicalConsultant}
                    label={t(`simulation.fields.${COST_MEDICAL_CONSULTANT}.label`)}
                    helper={t(`simulation.fields.${COST_MEDICAL_CONSULTANT}.helper`)}
                    onBlur={handleSave}
                />
            </div>
            <div className={editionClasses.fieldContainer}>
                <Field
                    type="currency"
                    slug={COST_PHYSIO_PODIA}
                    defaultValue={costPhysioPodia}
                    label={t(`simulation.fields.${COST_PHYSIO_PODIA}.label`)}
                    helper={t(`simulation.fields.${COST_PHYSIO_PODIA}.helper`)}
                    onBlur={handleSave}
                />
            </div>
            <div className={editionClasses.fieldContainer}>
                <Field
                    type="currency"
                    slug={COST_OTHER}
                    defaultValue={costOther}
                    label={t(`simulation.fields.${COST_OTHER}.label`)}
                    helper={t(`simulation.fields.${COST_OTHER}.helper`)}
                    onBlur={handleSave}
                />
            </div>
        </Paper>
    );
};

StaffCosts.propTypes = {
};

StaffCosts.defaultProps = {
};

export default StaffCosts;