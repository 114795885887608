import React from "react";
import Typography from "@material-ui/core/Typography";
import makeStyles from "@material-ui/core/styles/makeStyles";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import Button from "@material-ui/core/Button";
import {fetch, save} from "../../../../actions/defaultValues/defaultValuesAction";
import PropTypes from "prop-types";
import ShareRoundedIcon from '@material-ui/icons/ShareRounded';
import SettingsIcon from '@material-ui/icons/Settings';
import DialogTitle from "@material-ui/core/DialogTitle";
import {useGetStats} from "../../../../actions/user/userAction";
import {useHasAdminRole} from "../../../../managers/userManager";
import {API_URL} from "../../../../constants/api";

const useStyles = makeStyles(theme => ({
    DefaultValueDialogTitleRoot: {
        '& > :last-child': {
            borderBottom: 'none'
        }
    },
    titleContainer: {
        position: 'relative',
    },
    title: {
        paddingBottom: '6px'
    },
    subtitle: {
        textTransform: 'none'
    },
    left: {
        position: 'absolute',
        top: '50%',
        transform: 'translate(0, -50%)',
        left: 0,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        '& .MuiButton-root': {
            margin: '4px 0'
        }
    },
    right: {
        position: 'absolute',
        top: '50%',
        transform: 'translate(0, -50%)',
        right: 0,
        display: 'flex'
    },
    boutonAnnuler: {
        marginRight: "8px"
    }
}));

const DefaultValueDialogTitle = ({handleClose}) => {
    const classes = useStyles();
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const error = useSelector(state => state.defaultValues.error);
    const pending = useSelector(state => state.defaultValues.fetchPending);
    const hasChange = useSelector(state => state.defaultValues.hasChange);
    const getStats = useGetStats();
    const isAdmin = useHasAdminRole();

    const cancel = () => {
        if(hasChange) dispatch(fetch());
        handleClose();
    }

    const handleSave = () => {
        dispatch(save());
        handleClose();
    }

    if (pending) return null;

    return (
        <DialogTitle className={classes.DefaultValueDialogTitleRoot}>
            <div>
                <div className={classes.titleContainer}>
                    <div className={classes.left}>
                        <Button
                            variant="contained"
                            color="primary"
                            startIcon={<ShareRoundedIcon />}
                            size="small"
                            href={getStats}
                        >
                            {t('administration.dialog.exporter')}
                        </Button>
                        {isAdmin && (
                            <Button
                                variant="contained"
                                color="primary"
                                startIcon={<SettingsIcon />}
                                size="small"
                                target="_blank"
                                href={`${API_URL}/admin`}
                            >
                                {t('administration.dialog.backOffice')}
                            </Button>
                        )}
                    </div>
                    <Typography className={classes.title} variant="h4">{t('administration.dialog.title')}</Typography>
                    <Typography className={classes.subtitle} variant="h5">{t('administration.dialog.subTitle')}</Typography>
                    <div className={classes.right}>
                        <Button variant="contained" onClick={cancel} className={classes.boutonAnnuler}>
                            {t('administration.dialog.cancel')}
                        </Button>
                        <Button variant="contained" color="secondary" onClick={handleSave} disabled={error}>
                            {t('administration.dialog.valider')}
                        </Button>
                    </div>
                </div>
            </div>
        </DialogTitle>
    );
}

DefaultValueDialogTitle.propTypes = {
    handleClose: PropTypes.func,
};

DefaultValueDialogTitle.defaultProps = {
    handleClose: () => {},
};

export default DefaultValueDialogTitle;