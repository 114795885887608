import { createStore, compose, applyMiddleware } from "redux";
import reduxImmutableStateInvariant from "redux-immutable-state-invariant";
import thunk from "redux-thunk";

import createRootReducer from "../reducers";

function configureStoreProd(initialState) {
    const middlewares = [
        thunk,
    ];

    return createStore(
        createRootReducer(),
        initialState,
        compose(applyMiddleware(...middlewares))
    );
}

function configureStoreDev(initialState) {

    const middlewares = [
        // Redux middleware that spits an error on you when you try to mutate your state either inside a dispatch or between dispatches.
        reduxImmutableStateInvariant(),
        thunk,
        // logger
    ];

    const composeEnhancers =
        window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose; // add support for Redux dev tools

    return createStore(
        createRootReducer(),
        initialState,
        composeEnhancers(applyMiddleware(...middlewares))
    );
}
const configureStore =
    process.env.NODE_ENV === "production" || process.env.NODE_ENV === "staging"
        ? configureStoreProd
        : configureStoreDev
;

export default configureStore;
