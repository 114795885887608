import {
    CENTRE_EDIT_IS_CLIENT,
    CENTRE_EDIT_IS_CLIENT_ERROR,
    CENTRE_EDIT_IS_CLIENT_FULFILLED,
    STATIC_DATA_FETCH_ERROR,
    STATIC_DATA_FETCH_FULFILLED,
    STATIC_DATA_FETCH_PENDING
} from "../../constants/staticData";
import regionReducer, {INITIAL_STATE as REGION_INITIAL_STATE} from "./regionReducer";
import healthDivisionReducer, {INITIAL_STATE as HEALTH_DIVISION_INITIAL_STATE} from "./healthDivisionReducer";
import centreReducer, {INITIAL_STATE as CENTRE_INITIAL_STATE} from "./centreReducer";
import listEditionReducer , {INITIAL_STATE as LIST_EDITION_INITIAL_STATE} from "../edition/listEditionRecucer";
import nhsReducer, {INITIAL_STATE as NHS_INITIAL_STATE} from "./nhsReducer";
import {EDIT_VALUES_ERROR, EDIT_VALUES_FULFILLED, EDIT_VALUES_PENDING} from "../../constants/edition";
import {ACTION_LOGOUT} from "../../constants/authentication";

const INITIAL_STATE = {
    pending: false,
    fulfilled: false,
    error: false,
    regions: {},
    healthDivisions: {},
    centres: {},
    nhs: {},
    edition: LIST_EDITION_INITIAL_STATE
};

export default function staticDataReducer (state = INITIAL_STATE, {type, payload}) {
    switch(type) {
        case STATIC_DATA_FETCH_PENDING : {
            return {
                ...state,
                pending: true,
                fulfilled: false,
                error: false
            }
        }
        case STATIC_DATA_FETCH_FULFILLED : {
            const {data} = payload;
            // eslint-disable-next-line camelcase
            const {regions, health_divisions, centres, nhs} = data;

            return {
                ...state,
                pending: false,
                fulfilled: true,
                error: false,
                regions: Object.keys(regions).reduce((accu, idReg) => {
                    accu[idReg] = regionReducer(REGION_INITIAL_STATE, {type, payload: {...regions[idReg]}});
                    return accu;
                }, {}),
                healthDivisions: Object.keys(health_divisions).reduce((accu, idReg) => {
                    accu[idReg] = healthDivisionReducer(HEALTH_DIVISION_INITIAL_STATE, {type, payload: {...health_divisions[idReg]}});
                    return accu;
                }, {}),
                centres: Object.keys(centres).reduce((accu, idReg) => {
                    accu[idReg] = centreReducer(CENTRE_INITIAL_STATE, {type, payload: {...centres[idReg]}});
                    return accu;
                }, {}),
                nhs: Object.keys(nhs).reduce((accu, id) => {
                    const nhsID = nhs[id].id;
                    accu[nhsID] = nhsReducer(NHS_INITIAL_STATE, {type, payload: {...nhs[id]}});
                    return accu;
                }, {}),
            }
        }
        case STATIC_DATA_FETCH_ERROR : {
            return {
                ...state,
                pending: false,
                fulfilled: false,
                error: true
            }
        }

        case CENTRE_EDIT_IS_CLIENT :
        case CENTRE_EDIT_IS_CLIENT_FULFILLED :
        case CENTRE_EDIT_IS_CLIENT_ERROR : {
            const {id, saveId, isClient} = payload;
            let editionType = EDIT_VALUES_PENDING;
            if(type === CENTRE_EDIT_IS_CLIENT_FULFILLED) editionType = EDIT_VALUES_FULFILLED;
            if(type === CENTRE_EDIT_IS_CLIENT_ERROR) editionType = EDIT_VALUES_ERROR;

            const edition = listEditionReducer(state.edition, {type: editionType});
            const currentSaveId = type === CENTRE_EDIT_IS_CLIENT
                ? edition.saveId
                : saveId;
            return {
                ...state,
                edition,
                centres: {
                    ...state.centres,
                    [id]: centreReducer(state.centres[id], {type, payload: {saveId: currentSaveId, isClient}})
                }
            }
        }
        case ACTION_LOGOUT: {
            return {
                ...state,
                ...INITIAL_STATE
            }
        }
    }

    return state;
}
