export const HOME_PAGE = "HOME_PAGE";
export const MAP_PAGE = "MAP_PAGE";
export const LOGIN_PAGE = "LOGIN_PAGE";
export const RESET_PASSWORD_PAGE = "RESET_PASSWORD_PAGE";
export const INIT_PASSWORD_PAGE = "INIT_PASSWORD_PAGE";
export const SIMULATION_PAGE = "SIMULATION_PAGE";
export const RESULTAT_PAGE = "RESULTAT_PAGE";
export const MATERIAL_DEMO_PAGE = "MATERIAL_DEMO_PAGE";
export const REPORT_PAGE = "REPORT_PAGE";

export const NOT_FOUND_PAGE = "NOT_FOUND_PAGE";
export const ERROR_PAGE = "ERROR_PAGE";
export const BAD_REQUEST_PAGE = "BAD_REQUEST_PAGE";
export const FORBIDDEN_PAGE = "FORBIDDEN_PAGE";


export const ERROR_PAGES = {
    400: BAD_REQUEST_PAGE,
    403: FORBIDDEN_PAGE,
    404: NOT_FOUND_PAGE,
    500: ERROR_PAGE,
};