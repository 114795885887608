import React from "react";
import routes, {getUrl} from "./index";
import {Switch, Redirect, Route} from "react-router";
import {useSelector} from "react-redux";
import {MAP_PAGE, HOME_PAGE, LOGIN_PAGE} from "../constants/router";
import Layout from "../layouts/Layout";

const Routes = () => {
    const loggedIn = useSelector(state => state.authentication.loggedIn);

    const renderRouteComponent = (name, component, isPublic, withHeaders=true) => {
        if(name === HOME_PAGE && !loggedIn) {
            return <Redirect to={getUrl({page: LOGIN_PAGE, keepParams: false, keepQuery: false})} />;
        }
        if(name === HOME_PAGE && loggedIn) {
            return <Redirect to={getUrl({page: MAP_PAGE, keepParams: false, keepQuery: false})} />;
        }
        if(!loggedIn && !isPublic) {
            return <Redirect to={getUrl({page: LOGIN_PAGE, keepParams: false, keepQuery: false})} />;
        }
        if(loggedIn && name === LOGIN_PAGE) {
            return <Redirect to={getUrl({page: MAP_PAGE, keepParams: false, keepQuery: false})} />;
        }

        return (<Layout withHeaders={withHeaders}>{component}</Layout>);

    }

    return (
        <Switch>
            {routes.map(({name, path, component, isPublic, withHeaders}, i) => (
                <Route
                    key={i}
                    path={path}
                    exact
                    render={() => renderRouteComponent(name, component, isPublic, withHeaders)}
                />
            ))}
        </Switch>
    );
};

export default Routes;
