import React, {useState} from "react";
import PropTypes from "prop-types";
import {useTranslation} from "react-i18next";
import {
    COST_IDE_BAND_4_HOUR,
    COST_IDE_BAND_5_HOUR,
    COST_IDE_BAND_6_HOUR,
    COST_IDE_BAND_7_HOUR
} from "../../../../constants/simulationFields";
import makeStyles from "@material-ui/core/styles/makeStyles";
import cn from "classnames";
import Typography from "@material-ui/core/Typography";
import {getCostNurseStay} from "../../../../managers/simulationValuesManager";
import useEditionStyles from "../../../Simulation/Shared/useEditionStyles";
import Field from "../../../Simulation/Edition/Field/Field";

const useStyles = makeStyles(theme => ({
    fields: {
        display: "flex",
        width: "356px",
        justifyContent: "space-around",
        marginLeft: "-34px"
    },
    subtitle: {
        display: "block",
        textAlign: "center",
        paddingBottom: "15px",
    },
    field: {
        flexBasis: "81px"
    },
    caption: {
        color: theme.palette.text.disabled,
        margin: '-15px 0 12px 14px',
    }
}));

const NurseCosts = ({costIDEBand4, costIDEBand5, costIDEBand6, costIDEBand7, handleSave, values}) => {
    const editionClasses = useEditionStyles();
    const {t} = useTranslation();
    const classes = useStyles();

    return (
        <>
            <Typography variant="h6" className={classes.subtitle}>{t('simulation.fields.cost-ide-band.label')}</Typography>
            <div className={cn(editionClasses.fieldContainer, classes.fields)}>
                <Field
                    type="currency"
                    slug={COST_IDE_BAND_4_HOUR}
                    defaultValue={costIDEBand4}
                    label={t(`simulation.fields.${COST_IDE_BAND_4_HOUR}.label`)}
                    onBlur={handleSave}
                    className={classes.field}
                    size="small"
                />
                <Field
                    type="currency"
                    slug={COST_IDE_BAND_5_HOUR}
                    defaultValue={costIDEBand5}
                    label={t(`simulation.fields.${COST_IDE_BAND_5_HOUR}.label`)}
                    onBlur={handleSave}
                    className={classes.field}
                    size="small"
                />
                <Field
                    type="currency"
                    slug={COST_IDE_BAND_6_HOUR}
                    defaultValue={costIDEBand6}
                    label={t(`simulation.fields.${COST_IDE_BAND_6_HOUR}.label`)}
                    onBlur={handleSave}
                    className={classes.field}
                    size="small"
                />
                <Field
                    type="currency"
                    slug={COST_IDE_BAND_7_HOUR}
                    defaultValue={costIDEBand7}
                    label={t(`simulation.fields.${COST_IDE_BAND_7_HOUR}.label`)}
                    onBlur={handleSave}
                    className={classes.field}
                    size="small"
                />
            </div>
            <div className={classes.caption}>
                <Typography variant="caption">
                    {t(`simulation.fields.cost-ide-band.helper`)}
                </Typography>
            </div>
            <div className={classes.fields}>
                <Typography>£{getCostNurseStay(values, 'value')} {t(`simulation.fields.cost-ide-band-total.label`)}</Typography>
            </div>
        </>
    );
};

NurseCosts.propTypes = {
    costIDEBand4: PropTypes.number.isRequired,
    costIDEBand5: PropTypes.number.isRequired,
    costIDEBand6: PropTypes.number.isRequired,
    costIDEBand7: PropTypes.number.isRequired,
    values: PropTypes.object.isRequired,
    handleSave: PropTypes.func.isRequired
};

NurseCosts.defaultProps = {};

export default NurseCosts;