import {PARAM_TYPE_GEO_REG, ACTION_CHANGE_MAP_MIN_MAX, ACTION_ZONE_OVER, ACTION_ZONE_OUT} from "../../constants/map";

const INITIAL_STATE = {
    typeGeo: null,
    population: null,
    min: 0,
    max: 0,
    hover: null
}

export default function mapReducer (state = INITIAL_STATE, {type, payload}) {
    switch (type) {
        case ACTION_CHANGE_MAP_MIN_MAX: {
            const {regions, healthDivisions, typeGeo, population} = payload;

            const reducer = (accu, id) => {
                const pop = typeGeo === PARAM_TYPE_GEO_REG
                    ? parseFloat(regions[id][population], 10)
                    : parseFloat(healthDivisions[id][population], 10)
                ;
                if(accu.min === null || pop < accu.min) {
                    accu.min = pop;
                }
                if(accu.max === null || pop > accu.max) {
                    accu.max = pop;
                }
                return accu;
            }

            if(typeGeo !== state.typeGeo || population !== state.population) {
                const {min, max} = typeGeo === PARAM_TYPE_GEO_REG
                    ? Object.keys(regions).reduce(reducer, {min: null, max: null})
                    : Object.keys(healthDivisions).reduce(reducer, {min: null, max: null})
                ;
                return {...state, typeGeo, population, min, max};
            }

            return state;
        }
        case ACTION_ZONE_OVER: {
            const {id} = payload;

            return {
                ...state,
                hover: id
            }
        }
        case ACTION_ZONE_OUT: {
            const {id} = payload;
            if(state.hover !== id) return state;

            return {
                ...state,
                hover: null
            }

        }
    }

    return state;
}