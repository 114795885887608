import makeStyles from "@material-ui/core/styles/makeStyles";

const useEditionStyles = makeStyles(theme => ({
    title: {
        textAlign: 'center',
        paddingBottom: '24px',
        color: theme.palette.text.secondary
    },
    fieldContainer: {
        position: 'relative',
        width: '280px',
        marginBottom: '12px',
        marginLeft: 'auto',
        marginRight: 'auto',
    },
    fieldHalfContainer: {
        position: 'relative',
        width: '280px',
        marginBottom: '12px',
        marginLeft: 'auto',
        marginRight: 'auto',
        display: 'flex',
        alignItems: 'center'
    },
    infosContainer: {
        color: theme.palette.text.secondary,
        display: 'flex',
        flexDirection: 'column',
        textAlign: 'center',
    },
    infosIcon: {
        flex: 1,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    infosLabel: {

    },
    infosNumber: {
        flex: 1,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        whiteSpace : 'nowrap',
    },
    infosCaption: {
        color: theme.palette.text.disabled,
        marginLeft: 'auto',
        marginRight: 'auto',
    },
    infosCaptionBis: {
        color: theme.palette.text.disabled,
    },
    space: {
        height: '10px',
        width: '100%',
    }
}));

export default useEditionStyles;