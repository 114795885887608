import {
    CENTRE_EDIT_IS_CLIENT,
    CENTRE_EDIT_IS_CLIENT_ERROR, CENTRE_EDIT_IS_CLIENT_FULFILLED,
    STATIC_DATA_FETCH_FULFILLED
} from "../../constants/staticData";
import editionReducer, {INITIAL_STATE as EDITION_INITIAL_STATE} from "../edition/editionReducer";
import {EDIT_VALUES_ERROR, EDIT_VALUES_FULFILLED, EDIT_VALUES_PENDING} from "../../constants/edition";

export const INITIAL_STATE = {
    id: null,
    idReg: null,
    idHD: null,
    code: null,
    lab: null,
    status: null,
    champ: null,
    isClient: false,
    isSdc: false,
    lat: null,
    lng: null,
    edition: EDITION_INITIAL_STATE
};

export default function centreReducer (state = INITIAL_STATE, {type, payload}) {
    switch(type) {
        case STATIC_DATA_FETCH_FULFILLED: {
            const {id, idReg, idHD, code, lab, status, champ, isClient, isSdc, lat, lng} = payload;
            return {...state, id, idReg, idHD, code, lab, status, champ, isClient, isSdc, lat, lng};
        }
        case CENTRE_EDIT_IS_CLIENT: {
            const {saveId, isClient} = payload;

            return {
                ...state,
                isClient,
                edition: editionReducer(state.edition, {
                    type: EDIT_VALUES_PENDING,
                    payload: {
                        saveId,
                        values: {
                            isClient: [state.isClient, isClient]
                        },
                    }
                })
            }
        }
        case CENTRE_EDIT_IS_CLIENT_FULFILLED: {
            const {saveId} = payload;

            return {
                ...state,
                edition: editionReducer(state.edition, {
                    type: EDIT_VALUES_FULFILLED,
                    payload: {saveId}
                })
            }
        }
        case CENTRE_EDIT_IS_CLIENT_ERROR: {
            const {saveId} = payload;

            const edition = editionReducer(state.edition, {
                type: EDIT_VALUES_ERROR,
                payload: {saveId}
            });

            return {
                ...state,
                isClient: edition.errors[saveId].isClient[0],
                edition
            }
        }
    }
    return state;
};