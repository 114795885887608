import {
    DEFAULT_VALUE_ADD_NHS_TYPE,
    DEFAULT_VALUES_FETCH_FULFILLED,
} from "../../constants/defaultValues";

export const INITIAL_STATE = {
    type: null,
    id: null,
    error: false,
}

export default function defaultValueNHSReducer (state = INITIAL_STATE, {type, payload}) {
    switch (type) {
        case DEFAULT_VALUES_FETCH_FULFILLED: {
            const {id, type} = payload;
            return {
                ...state,
                type,
                id,
            }
        }
        case DEFAULT_VALUE_ADD_NHS_TYPE: {
            const {id, type} = payload;

            return {
                ...state,
                type,
                id,
                error: !type
            }
        }
    }

    return state;
}