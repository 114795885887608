import {checkPainManagement} from "./simulationManager";
import {
    ANNUAL_CONSUMABLE,
    CONSUMABLE_PER_DAY, CONSUMABLE_LARGE_AREA, CONSUMABLE_AVERAGE_AREA,
    CONSUMABLE_SMALL_AREA, DAY_AM_TIME, DAY_AM_TIME_BEGIN, DAY_PM_TIME_BEGIN,
    ANNUAL_STAY, DAY_PM_TIME
} from "../constants/simulationFields";
import {formatNumber} from "./simulationValuesManager";

export const checkDefaultValue = (fields, slug, value) => {
    switch (slug) {
        case ANNUAL_STAY:
        case ANNUAL_CONSUMABLE:
        case CONSUMABLE_PER_DAY: {
            return !checkPainManagement(
                slug === ANNUAL_STAY ? value : fields[ANNUAL_STAY].value,
                slug === ANNUAL_CONSUMABLE ? value : fields[ANNUAL_CONSUMABLE].value,
                slug === CONSUMABLE_PER_DAY ? value : fields[CONSUMABLE_PER_DAY].value
            );
        }
        case CONSUMABLE_SMALL_AREA: {
            return formatNumber(value, true) >= fields[CONSUMABLE_AVERAGE_AREA].value
                || formatNumber(value, true) >= fields[CONSUMABLE_LARGE_AREA].value;
        }
        case CONSUMABLE_AVERAGE_AREA: {
            return formatNumber(value, true) <= fields[CONSUMABLE_SMALL_AREA].value
                || formatNumber(value, true) >= fields[CONSUMABLE_LARGE_AREA].value;
        }
        case CONSUMABLE_LARGE_AREA: {
            return formatNumber(value, true) <= fields[CONSUMABLE_SMALL_AREA].value
                || formatNumber(value, true) <= fields[CONSUMABLE_AVERAGE_AREA].value;
        }
        case DAY_AM_TIME_BEGIN: {
            return value > (fields[DAY_AM_TIME_BEGIN].value + fields[DAY_AM_TIME].value);
        }
        case DAY_AM_TIME: {
            return value < 0 || ((value + fields[DAY_AM_TIME_BEGIN].value) > fields[DAY_PM_TIME_BEGIN].value);
        }
        case DAY_PM_TIME_BEGIN: {
            return value < (fields[DAY_AM_TIME_BEGIN].value + fields[DAY_AM_TIME].value)
                || value > (fields[DAY_PM_TIME_BEGIN].value + fields[DAY_PM_TIME].value);
        }
        case DAY_PM_TIME: {
            return value < 0;
        }
    }

    return false;
}

export const nhsCodesList = (nhsList, codes) => {
    const list = [];
    nhsList.forEach(nhs => {
        if (codes.find(code => code === nhs.code)) {
            if (list[nhs.year]) {
                list[nhs.year].push(nhs);
            } else {
                list[nhs.year] = [nhs];
            }
        }
    })

    return list;
}