import React from "react";
import PropTypes from "prop-types";
import Logout from "../Authentication/Logout/Logout";
import HeaderDefaultValue from "./HeaderDefaultValue";
import makeStyles from "@material-ui/core/styles/makeStyles";
import sizes from "../../Material/theme/sizes";
import HeaderMonProfil from "./HeaderMonProfil";
import HeaderLogoHomePageLink from "./HeaderLogoHomePageLink";
import References from "./References";
import HeaderLabel from "./HeaderLabel";

const useStyles = makeStyles(theme => ({
    HeaderRoot: {
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        zIndex: 2,
        height: `${sizes.headerHeight}px`,
        backgroundColor: theme.palette.secondary.main,
        paddingLeft: `${sizes.containerPadding + 8}px`,
        paddingRight: `${sizes.containerPadding + 8}px`,
        display: 'flex',
        alignItems: 'center'
    },
    logo: {
        display: 'block'
    },
    links: {
        flex: 1,
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
    }
}));

const Header = () => {
    const classes = useStyles();

    return (
        <header className={classes.HeaderRoot}>
            <HeaderLogoHomePageLink />
            <HeaderLabel />
            <div className={classes.links}>
                <HeaderDefaultValue />
                <References />
                <HeaderMonProfil />
                <Logout />
            </div>
        </header>
    );
};

Header.propTypes = {

};

Header.defaultProps = {

};

export default Header;