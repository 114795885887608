import React from "react";
import PropTypes from "prop-types";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {
    CONSUMABLE_LARGE_AREA,
    CONSUMABLE_AVERAGE_AREA,
    CONSUMABLE_SMALL_AREA
} from "../../../../constants/simulationFields";
import Field from "../../../Simulation/Edition/Field/Field";
import useEditionStyles from "../../../Simulation/Shared/useEditionStyles";
import {setValue} from "../../../../actions/defaultValues/defaultValuesAction";
import Paper from "../../../Simulation/Shared/Paper";
import makeStyles from "@material-ui/core/styles/makeStyles";


const useStyles = makeStyles(theme => ({
    zoneConsommableRoot: {
    },
}));

const ConsumableZone = () => {
    const classes = useStyles();
    const editionClasses = useEditionStyles();
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const {value: consumableSmallZone, error: consumableSmallZoneError} = useSelector(state => state.defaultValues.fields?.[CONSUMABLE_SMALL_AREA]);
    const {value: consumableAverageZone, error: consumableAverageZoneError} = useSelector(state => state.defaultValues.fields?.[CONSUMABLE_AVERAGE_AREA]);
    const {value: consumableLargeZone, error: consumableLargeZoneError} = useSelector(state => state.defaultValues.fields?.[CONSUMABLE_LARGE_AREA]);
    const errors = {
        [CONSUMABLE_SMALL_AREA]: consumableSmallZoneError,
        [CONSUMABLE_AVERAGE_AREA]: consumableAverageZoneError,
        [CONSUMABLE_LARGE_AREA]: consumableLargeZoneError,
}

    const errorChecker = (slug) => {
        return !errors[slug];
    }

    const handleSave = (slug, value) => {
        dispatch(setValue(slug, value));
    }

    return (
        <Paper title={t('simulation.zoneConsommable')}>
            <div className={editionClasses.fieldContainer}>
                <Field
                    type="float"
                    size="small"
                    slug={CONSUMABLE_SMALL_AREA}
                    defaultValue={consumableSmallZone}
                    label={t(`simulation.fields.${CONSUMABLE_SMALL_AREA}.label`)}
                    onBlur={handleSave}
                    errorChecker={errorChecker}
                />
                <Field
                    type="float"
                    size="small"
                    slug={CONSUMABLE_AVERAGE_AREA}
                    defaultValue={consumableAverageZone}
                    label={t(`simulation.fields.${CONSUMABLE_AVERAGE_AREA}.label`)}
                    onBlur={handleSave}
                    errorChecker={errorChecker}
                />
                <Field
                    type="float"
                    size="small"
                    slug={CONSUMABLE_LARGE_AREA}
                    defaultValue={consumableLargeZone}
                    label={t(`simulation.fields.${CONSUMABLE_LARGE_AREA}.label`)}
                    onBlur={handleSave}
                    errorChecker={errorChecker}
                />
            </div>
        </Paper>
    );
};

ConsumableZone.propTypes = {
};

ConsumableZone.defaultProps = {
};

export default ConsumableZone;