import React from "react";
import PropTypes from "prop-types";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {
    CONSUMABLE_COST, CONSUMABLE_PROPORTION
} from "../../../../constants/simulationFields";
import Field from "../../../Simulation/Edition/Field/Field";
import useEditionStyles from "../../../Simulation/Shared/useEditionStyles";
import {setValue} from "../../../../actions/defaultValues/defaultValuesAction";
import Paper from "../../../Simulation/Shared/Paper";
import makeStyles from "@material-ui/core/styles/makeStyles";


const useStyles = makeStyles(theme => ({
    zoneConsommableRoot: {
        display: "flex",
        flexDirection: "row"
    }
}));

const ConsumableCost = () => {
    const classes = useStyles();
    const editionClasses = useEditionStyles();
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const {value: consumableCost, error: amountConsumableError} = useSelector(state => state.defaultValues.fields?.[CONSUMABLE_COST]);
    const {value: consumableProportion, error: consumableProportionError} = useSelector(state => state.defaultValues.fields?.[CONSUMABLE_PROPORTION]);

    const errorChecker = () => {
        return !amountConsumableError || !consumableProportionError;
    }

    const handleSave = (slug, value) => {
        dispatch(setValue(slug, parseFloat(value.replace('£', '').replace('%', '').replace(',', ''))));
    }

    return (
        <Paper title={t('simulation.consumableCost')}>
            <div className={editionClasses.fieldContainer}>
                <Field
                    type="currency"
                    slug={CONSUMABLE_COST}
                    className={editionClasses.fieldContainer}
                    defaultValue={consumableCost}
                    label={t(`simulation.fields.${CONSUMABLE_COST}.label`)}
                    onBlur={handleSave}
                    errorChecker={errorChecker}
                />
                <Field
                    type="percent"
                    slug={CONSUMABLE_PROPORTION}
                    className={editionClasses.fieldContainer}
                    defaultValue={consumableProportion}
                    label={t(`simulation.fields.${CONSUMABLE_PROPORTION}.label`)}
                    helper={t(`simulation.fields.${CONSUMABLE_PROPORTION}.helper`)}
                    onBlur={handleSave}
                    errorChecker={errorChecker}
                />
            </div>
        </Paper>
    );
};

ConsumableCost.propTypes = {
};

ConsumableCost.defaultProps = {
};

export default ConsumableCost;