import {
    DEFAULT_VALUE_ADD_NHS,
    DEFAULT_VALUES_FETCH_FULFILLED,
    DEFAULT_VALUES_SET,
} from "../../constants/defaultValues";
import {formatNumber} from "../../managers/simulationValuesManager";

export const INITIAL_STATE = {
    type: null,
    year: null,
    code: null,
    status: null,
    value: null,
    percent: 100,
    error: false,
}

export default function defaultValueNHSReducer (state = INITIAL_STATE, {type, payload}) {
    switch (type) {
        case DEFAULT_VALUES_FETCH_FULFILLED: {
            const {year, code, status, value, percent, type} = payload;
            return {
                ...state,
                type,
                year,
                code,
                status,
                value,
                percent
            }
        }
        case DEFAULT_VALUES_SET: {
            const {value: nhs} = payload;
            const {value, percent} = nhs;

            return {
                ...state,
                value,
                percent,
                error: Number.isNaN(value) || value === 0
            }
        }
        case DEFAULT_VALUE_ADD_NHS: {
            const {year, code, status, value, percent, type} = payload;
            return {
                ...state,
                type,
                year: (parseInt(year, 10) + 1).toString(),
                code,
                status,
                percent,
                value,
                error: Number.isNaN(value) || value === 0
            }
        }
    }

    return state;
}