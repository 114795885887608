import React from "react";
import PropTypes from "prop-types";
import makeStyles from "@material-ui/core/styles/makeStyles";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import NhsCost from "./NhsCost";


const useStyles = makeStyles(theme => ({
    rows: {
        display: "flex",
        flexWrap: "wrap",
        width: "50%"
    }
}));

const NhsCostList = () => {
    const classes = useStyles();
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const nhsList = useSelector(state => state.staticData.nhs);

    if (!nhsList) return null;

    return (
        <>
            {Object.entries(nhsList).map((nhs, index) => (
                <NhsCost key={index}  idType={nhs[1].id} type={nhs[1].type} codes={nhs[1].codes} />
            ))}
        </>
    );
}

NhsCostList.propTypes = {
};

NhsCostList.defaultProps = {
};

export default NhsCostList;
