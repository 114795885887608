import {alpha} from '@material-ui/core/styles';
import colors from "../colors.json";

const SmallButton = theme => ({
    textTransform: 'none',
    padding: '6px 12px',
    '&$outlined': {
        padding: '5px 20px',
    },
    '& $startIcon': {
        marginRight: '4px',
    },
    '& $endIcon': {
        marginLeft: '4px',
    },
    '& $label': {
        minHeight: '18px',//Taille de l'icon, à mettre si la fontSize est plus petite
    }
});

export const MediumButton = theme => ({
    padding: '8px 20px',
    '&$outlined': {
        padding: '7px 20px',
    },
    '& $startIcon': {
        marginRight: '6px',
    },
    '& $endIcon': {
        marginLeft: '6px',
    },
    '& $label': {
        minHeight: '20px',//Taille de l'icon, à mettre si la fontSize est plus petite
    }
});

const LargeButton = theme => ({
    padding: '10px 22px',
    '&$outlined': {
        padding: '9px 20px',
    },
    '& $startIcon': {
        marginRight: '8px',
    },
    '& $endIcon': {
        marginLeft: '8px',
    },
    '& $label': {
        minHeight: '22px',//Taille de l'icon, à mettre si la fontSize est plus petite
    }
});

const ContainedButton = theme => ({
    backgroundColor: theme.palette.grey[100],
    color: theme.palette.text.secondary,
    '&:hover': {
        backgroundColor: theme.palette.grey[200],
        // Reset on touch devices, it doesn't add specificity
        '@media (hover: none)': {
            backgroundColor: "#fff",
        },
    },
    '&$disabled': {
        color: theme.palette.text.disabled,
        backgroundColor: '#ECF1F2',
        '& $startIcon': {
            color: theme.palette.text.disabled,
        },
        '& $endIcon': {
            color: theme.palette.text.disabled,
        }
    },
    '& $startIcon': {
        color: theme.palette.text.secondary,
    },
    '& $endIcon': {
        color: theme.palette.text.secondary,
    }
});
const ContainedPrimaryButton = theme => ({
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.text.secondary,
    '&:hover': {
        backgroundColor: theme.palette.primary.dark,
    },
    '& $startIcon': {
        color: theme.palette.text.secondary,
    },
    '& $endIcon': {
        color: theme.palette.text.secondary,
    }
});
export const ContainedSecondaryButtonBase = theme => ({
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.text.contrastText,
    '&:hover': {
        backgroundColor: theme.palette.secondary.dark,
        color: theme.palette.text.contrastText,
    },

});
const ContainedSecondaryButton = theme => ({
    ...ContainedSecondaryButtonBase(theme),
    '& $startIcon': {
        color: theme.palette.text.contrastText,
    },
    '& $endIcon': {
        color: theme.palette.text.contrastText,
    }
})


export const OutlinedButtonBase = theme => ({
    backgroundColor: alpha(theme.palette.grey[50], 0.5),
    color: theme.palette.secondary.main,
    border: `solid 1px ${theme.palette.secondary.main}`,
    '&:hover': {
        border: `solid 1px ${theme.palette.secondary.main}`,
        color: theme.palette.secondary.dark,
        backgroundColor: alpha(theme.palette.grey[100], 0.5),
        // Reset on touch devices, it doesn't add specificity
        '@media (hover: none)': {
            backgroundColor: "#fff",
        },
    },
    '&$disabled': {
        color: theme.palette.text.disabled,
        borderColor: '#ECF1F2',
    },
});
const OutlinedButton = theme => ({
    ...OutlinedButtonBase(theme),
    '&$disabled': {
        ...OutlinedButtonBase(theme)['&$disabled'],
        '& $startIcon': {
            color: theme.palette.text.disabled,
        },
        '& $endIcon': {
            color: theme.palette.text.disabled,
        }
    },
    '& $startIcon': {
        color: theme.palette.secondary.main,
    },
    '& $endIcon': {
        color: theme.palette.secondary.main,
    }
})

const OutlinedPrimaryButton = theme => ({
    color: theme.palette.primary.main,
    border: `solid 1px ${theme.palette.primary.main}`,
    '&:hover': {
        color: theme.palette.primary.main,
        backgroundColor: alpha(theme.palette.grey[100], 0.8),
        '& $startIcon': {
            color: theme.palette.primary.main,
        },
        '& $endIcon': {
            color: theme.palette.primary.main,
        }
    },
    '& $startIcon': {
        color: theme.palette.primary.main,
    },
    '& $endIcon': {
        color: theme.palette.primary.main,
    }
});
const OutlinedSecondaryButton = theme => ({
    color: theme.palette.secondary.main,
    border: `solid 1px ${theme.palette.secondary.main}`,
    '&:hover': {
        borderColor: theme.palette.secondary.main,
        color: theme.palette.secondary.main,
        backgroundColor: alpha(theme.palette.grey[100], 0.5),
        '& $startIcon': {
            color: theme.palette.secondary.main,
        },
        '& $endIcon': {
            color: theme.palette.secondary.main,
        }
    },
    '&$disabled': {
        ...OutlinedButtonBase(theme)['&$disabled'],
    },
    '& $startIcon': {
        color: theme.palette.secondary.main,
    },
    '& $endIcon': {
        color: theme.palette.secondary.main,
    }
});

const TextButton = theme => ({
    '&$disabled': {
        color: theme.palette.text.disabled,
        '& $startIcon': {
            color: theme.palette.text.disabled,
        },
        '& $endIcon': {
            color: theme.palette.text.disabled,
        },
    },
    '& $startIcon': {
        color: theme.palette.grey[400],
    },
    '& $endIcon': {
        color: theme.palette.grey[400],
    }
});

const TextPrimary = theme => ({
    '& $startIcon': {
        color: theme.palette.grey[400],
    },
    '& $endIcon': {
        color: theme.palette.grey[400],
    }
});

const TextSecondary = theme => ({
    color: theme.palette.secondary.main,
    '&:hover': {
        color: theme.palette.grey[100],
        '& $startIcon': {
            color: theme.palette.grey[100],
        },
        '& $endIcon': {
            color: theme.palette.grey[100],
        }
    },
    '&$disabled': {
        color: theme.palette.text.secondaryDisabled,
        '& $startIcon': {
            color: theme.palette.text.secondaryDisabled,
        },
        '& $endIcon': {
            color: theme.palette.text.secondaryDisabled,
        }
    },
    '& $startIcon': {
        color: theme.palette.secondary.main,
    },
    '& $endIcon': {
        color: theme.palette.secondary.main,
    }
});

const Button = theme => ({
    "root": {
        borderRadius: '9999px',
        color: theme.palette.text.main,
        minWidth: 0,
        ...MediumButton(theme),
    },
    "label": {
        ...theme.typography.body2
    },
    "contained": ContainedButton(theme),
    "containedPrimary": ContainedPrimaryButton(theme),
    "containedSecondary": ContainedSecondaryButton(theme),
    "outlined": OutlinedButton(theme),
    "outlinedPrimary": OutlinedPrimaryButton(theme),
    "outlinedSecondary": OutlinedSecondaryButton(theme),
    "text": TextButton(theme),
    "textPrimary": TextPrimary(theme),
    "textSecondary": TextSecondary(theme),
    "sizeLarge": LargeButton(theme),
    "sizeSmall": SmallButton(theme),
});

export default Button;
