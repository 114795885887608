import {
    USER_PASSWORD_ERROR,
    USER_PASSWORD_PENDING,
    USER_PASSWORD_SUCCES, USER_RESET_PASSWORD_ERROR,
    USER_RESET_PASSWORD_PENDING,
    USER_RESET_PASSWORD_SUCCES,
    USER_SAVE_ERROR,
    USER_SAVE_PENDING,
    USER_SAVE_SUCCES,
} from "../../constants/user";
import apiFetch from "../../fetch";
import {performLogin} from "../authentication/authenticationAction";
import qs from "qs";
import {API_URL} from "../../constants/api";

export const updatePassword = (form) => async (dispatch) => {
    const {password} = form
    if (!password) return null;

    dispatch({type: USER_PASSWORD_PENDING});
    try {
        const response = await apiFetch({isPublic: false, dispatch}).post('updatePassword', {password});
        dispatch({type: USER_PASSWORD_SUCCES});
    } catch(error) {
        console.error('error : ', error);
        dispatch({type: USER_PASSWORD_ERROR});
    }
}

export const updateUser = (slug, value) => async (dispatch, getState) => {
    if (!slug || value === null) return null;
    const oldUser = {...getState().authentication.user};
    dispatch({type: USER_SAVE_PENDING, payload: {slug, value}});

    try {
        const params = getState().authentication.user;
        const response = await apiFetch({isPublic: false, dispatch}).post('updateUser', params);
        dispatch({type: USER_SAVE_SUCCES});
    } catch(error) {
        console.error('error : ', error);
        dispatch({type: USER_SAVE_ERROR, payload: {user: oldUser}});
    }
}

export const resetPassword = (password, token, successCallback = () => null) => async (dispatch) => {
    if (!token || !password) return null;

    dispatch({type: USER_RESET_PASSWORD_PENDING});
    try {
        const response = await apiFetch({isPublic: true, dispatch}).post('resetPassword', {password, token});
        performLogin(response, dispatch);
        successCallback();
        dispatch({type: USER_RESET_PASSWORD_SUCCES});
    } catch(error) {
        console.error('error : ', error);
        dispatch({type: USER_RESET_PASSWORD_ERROR});
    }
}

export const useGetStats = () => {
    const params = {
        'bearer': localStorage.getItem('token')
    };
    return `${API_URL}/counter?${qs.stringify(params)}`;
}