const Tooltip = theme => ({
    tooltip: {
        backgroundColor: '#fff',
        ...theme.typography.caption,
        color: theme.palette.text.secondary,
        top: '15px',
        fontWeight: 'normal',
        textAlign: 'center',
        boxShadow: '0 1px 1px 0 rgba(0,0,0,0.14), 0 2px 1px -1px rgba(0,0,0,0.12), 0 1px 3px 0 rgba(0,0,0,0.2)'
    },
    arrow: {
        color: '#fff',
        '&:before': {
            boxShadow: '0 1px 1px 0 rgba(0,0,0,0.14), 0 2px 1px -1px rgba(0,0,0,0.12), 0 1px 3px 0 rgba(0,0,0,0.2)'
        }
    }
});

export default Tooltip;