import {
    DEFAULT_VALUE_ADD_NHS,
    DEFAULT_VALUES_FETCH_ERROR,
    DEFAULT_VALUES_FETCH_FULFILLED,
    DEFAULT_VALUES_FETCH_PENDING,
    DEFAULT_VALUES_SAVE,
    DEFAULT_VALUES_SAVE_ERROR,
    DEFAULT_VALUES_SAVE_FULFILLED,
    DEFAULT_VALUES_SET
} from "../../constants/defaultValues";
import defaultValuesNHSReducer, {INITIAL_STATE as AMOUNT_NHS_INITIAL_STATE} from "./defaultValuesNHSReducer";
import defaultValuesNHSTypesReducer, {INITIAL_STATE as AMOUNT_NHS_TYPE_INITIAL_STATE} from "./defaultValuesNHSTypesReducer";
import defaultValueReducer, {INITIAL_STATE as DEFAULT_INITIAL_STATE} from "./defaultValueReducer";
import {AMOUNT_NHS, AMOUNT_NHS_TYPE_LIST} from "../../constants/simulationFields";
import {checkDefaultValue} from "../../managers/defaultValueManager";
import {ACTION_LOGOUT} from "../../constants/authentication";

const INITIAL_STATE = {
    fetchPending: false,
    fetchFulfilled: false,
    fetchError: false,
    savePending: false,
    saveError: false,
    fields: {},
    error: false,
    hasChange: false,
}

export default function defaultValuesReducer(state = INITIAL_STATE, {type, payload}) {
    switch (type) {
        case DEFAULT_VALUES_FETCH_PENDING: {
            return {
                ...state,
                fetchPending: true,
                fetchError: false,
            }
        }
        case DEFAULT_VALUES_FETCH_FULFILLED: {
            const {data} = payload;
            const fields = Object.keys(data).reduce((accu, slug) => {
                const {value} = data[slug];
                if (slug === AMOUNT_NHS) {
                    accu[slug] = defaultValuesNHSReducer(AMOUNT_NHS_INITIAL_STATE, {type, payload: {slug, value}})
                } else if (slug === AMOUNT_NHS_TYPE_LIST) {
                    accu[slug] = defaultValuesNHSTypesReducer(AMOUNT_NHS_TYPE_INITIAL_STATE, {type, payload: {slug, value}})
                } else {
                    accu[slug] = defaultValueReducer(DEFAULT_INITIAL_STATE, {type, payload: {slug, value}});
                }
                return accu;
            }, {});

            return {
                ...state,
                fetchPending: false,
                fetchFulfilled: true,
                fields,
                hasChange: false
            }
        }
        case DEFAULT_VALUES_FETCH_ERROR: {
            return {
                ...state,
                fetchPending: false,
                fetchError: true,
            }
        }
        case DEFAULT_VALUES_SET: {
            const {index, value, slug, error: fieldError} = payload;
            const fields = {...state.fields};
            const error = checkDefaultValue(fields, slug, value) || fieldError;

            if (slug !== AMOUNT_NHS) {
                fields[slug] = defaultValueReducer(fields[slug], {type, payload: {value, error, slug}});
            } else {
                fields[slug] = defaultValuesNHSReducer(fields[slug], {type, payload: {error, value}})
            }

            return {
                ...state,
                fields,
                error: !!Object.values(fields).find((val) => val.error === true) || error,
                hasChange: true
            };
        }
        case DEFAULT_VALUE_ADD_NHS: {
            const {slug, value} = payload;
            const fields = {...state.fields};

            if (slug === AMOUNT_NHS) {
                fields[slug] = defaultValuesNHSReducer(fields[slug], {type, payload: {value}})
            }

            return {
                ...state,
                fields,
                hasChange: true
            };
        }
        case DEFAULT_VALUES_SAVE: {
            return {
                ...state,
                savePending: true,
                hasChange: false
            }
        }
        case DEFAULT_VALUES_SAVE_FULFILLED: {
            return {
                ...state,
                savePending: false,
            }
        }
        case DEFAULT_VALUES_SAVE_ERROR: {
            return {
                ...state,
                savePending: false,
                saveError: true,
            }
        }
        case ACTION_LOGOUT: {
            return {
                ...state,
                ...INITIAL_STATE
            }
        }
    }
    return state;
}