import React from "react";
import PropTypes from "prop-types";
import {useTranslation} from "react-i18next";
import {
    TIME_MEDICAL_CONSULTANT, TIME_PHYSIO_PODIA
} from "../../../../constants/simulationFields";
import makeStyles from "@material-ui/core/styles/makeStyles";
import cn from "classnames";
import useEditionStyles from "../../../Simulation/Shared/useEditionStyles";
import Field from "../../../Simulation/Edition/Field/Field";

const useStyles = makeStyles(theme => ({
}));

const OtherTime = ({timeMedicalConsultant, timPhysioPodia, handleSave}) => {
    const editionClasses = useEditionStyles();
    const {t} = useTranslation();
    const classes = useStyles();

    return (
        <>
            <div className={editionClasses.fieldContainer}>
                <Field
                    slug={TIME_MEDICAL_CONSULTANT}
                    defaultValue={timeMedicalConsultant}
                    label={t(`simulation.fields.${TIME_MEDICAL_CONSULTANT}.label`)}
                    onBlur={handleSave}
                    noHelper={false}
                    helper={t(`simulation.fields.${TIME_MEDICAL_CONSULTANT}.caption`)}
                    type="float"
                />
            </div>
            <div className={editionClasses.fieldContainer}>
                <Field
                    slug={TIME_PHYSIO_PODIA}
                    defaultValue={timPhysioPodia}
                    label={t(`simulation.fields.${TIME_PHYSIO_PODIA}.label`)}
                    onBlur={handleSave}
                    noHelper={false}
                    helper={t(`simulation.fields.${TIME_PHYSIO_PODIA}.caption`)}
                    type="float"
                />
            </div>
        </>
    );
};

OtherTime.propTypes = {
    timeMedicalConsultant: PropTypes.number.isRequired,
    timPhysioPodia: PropTypes.number.isRequired,
    handleSave: PropTypes.func.isRequired
};

OtherTime.defaultProps = {};

export default OtherTime;