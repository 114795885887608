import {
    DEFAULT_VALUE_ADD_NHS,
    DEFAULT_VALUES_FETCH_ERROR,
    DEFAULT_VALUES_FETCH_FULFILLED,
    DEFAULT_VALUES_FETCH_PENDING, DEFAULT_VALUES_SAVE, DEFAULT_VALUES_SAVE_ERROR, DEFAULT_VALUES_SAVE_FULFILLED,
    DEFAULT_VALUES_SET
} from "../../constants/defaultValues";
import apiFetch from "../../fetch";
import {AMOUNT_NHS} from "../../constants/simulationFields";

export const fetch = () => async (dispatch, getState) => {
    dispatch({type: DEFAULT_VALUES_FETCH_PENDING});
    try {
        const response = await apiFetch({isPublic: false, dispatch}).get(`/defaultsValues`);
        const {data} = response;
        dispatch({type: DEFAULT_VALUES_FETCH_FULFILLED, payload: {data}});
    } catch (error) {
        dispatch({type: DEFAULT_VALUES_FETCH_ERROR});
        console.error(error)
    }
}

export const setValue = (slug, value, error = false) => async (dispatch, getState) => {
    if (!slug) return;
    dispatch({type: DEFAULT_VALUES_SET, payload: {slug, value, error}});
}

export const addNewYearGhm = (slug, value) => async (dispatch, getState) => {
    if (!slug || !value) return;
    dispatch({type: DEFAULT_VALUE_ADD_NHS, payload: {slug, value}});
}

export const save = () => async (dispatch, getState) => {
    // Formater correctement
    const defaultValues = getState().defaultValues.fields;
    if (!defaultValues) return;
    const params = [];
    Object.values(defaultValues).forEach((defaultValue) => {
        if (defaultValue.slug === AMOUNT_NHS) {
            const nhs = [];
            defaultValue.value.forEach((valueNhs) => {
                nhs.push({
                    type: valueNhs.type,
                    percent: valueNhs.percent,
                    code: valueNhs.code,
                    year: valueNhs.year,
                    status: valueNhs.status,
                    value: valueNhs.value,
                });
            });
            params.push({
                slug: AMOUNT_NHS,
                value: nhs,
            })
        } else {
            params.push({
                slug: defaultValue.slug,
                value: defaultValue.value
            });
        }
    })
    dispatch({type: DEFAULT_VALUES_SAVE});
    try {
        const response = await apiFetch({isPublic: false, dispatch})
            .post('/defaultValue/save', {"fieldsValues": params});
        dispatch({type: DEFAULT_VALUES_SAVE_FULFILLED});
    } catch (error) {
        console.error('error : ', error);
        dispatch({type: DEFAULT_VALUES_SAVE_ERROR});
    }
}