import {
    QUERY_PARAM_TYPE_GEO,
    QUERY_PARAM_ID_REG,
    QUERY_PARAM_ID_HD,
    QUERY_PARAM_ID_ETAB,
    QUERY_PARAM_POPULATION,
    QUERY_PARAM_IS_SDC,
    QUERY_PARAM_STATUS_CLIENT,
    QUERY_PARAM_CHAMP_HD,
    QUERY_PARAM_IS_CLIENT, PARAM_TYPE_GEO_REG, PARAM_POPULATION_DNP, PARAM_TYPE_GEO_HD
} from "../constants/map";
import {useHistory} from "react-router";
import useRouter from "../hooks/router/useRouter";
import {useSelector} from "react-redux";
import useQuery from "../hooks/router/useQuery";

export const getDefaultParams = () => ({
    [QUERY_PARAM_TYPE_GEO]: PARAM_TYPE_GEO_REG,
    [QUERY_PARAM_ID_REG]: null,
    [QUERY_PARAM_ID_HD]: null,
    [QUERY_PARAM_ID_ETAB]: null,
    [QUERY_PARAM_POPULATION]: PARAM_POPULATION_DNP,
    [QUERY_PARAM_IS_SDC]: null,
    [QUERY_PARAM_STATUS_CLIENT]: null,
    [QUERY_PARAM_CHAMP_HD]: null,
    [QUERY_PARAM_IS_CLIENT]: null,
});

export const isSimulationsListParamsHasChange = (prevParams, nextParam) => (
    prevParams[QUERY_PARAM_ID_REG] !== nextParam[QUERY_PARAM_ID_REG] ||
    prevParams[QUERY_PARAM_ID_HD] !== nextParam[QUERY_PARAM_ID_HD] ||
    prevParams[QUERY_PARAM_STATUS_CLIENT] !== nextParam[QUERY_PARAM_STATUS_CLIENT] ||
    prevParams[QUERY_PARAM_CHAMP_HD] !== nextParam[QUERY_PARAM_CHAMP_HD] ||
    prevParams[QUERY_PARAM_IS_SDC] !== nextParam[QUERY_PARAM_IS_SDC] ||
    prevParams[QUERY_PARAM_IS_CLIENT] !== nextParam[QUERY_PARAM_IS_CLIENT]
);

export const useChangeIdReg = () => {
    const history = useHistory();
    const getUrl = useRouter();
    const idCentre = useQuery(QUERY_PARAM_ID_ETAB, getDefaultParams()[QUERY_PARAM_ID_ETAB]);
    const centres = useSelector(state => state.staticData.centres);

    return (idReg, otherQuery = {}) => {
        idReg = !idReg ? null : parseInt(idReg, 10);
        const nextQuery = {
            ...otherQuery,
            [QUERY_PARAM_TYPE_GEO]: PARAM_TYPE_GEO_REG,
            [QUERY_PARAM_ID_REG]: idReg,
            [QUERY_PARAM_ID_HD]: null,
            [QUERY_PARAM_ID_ETAB]: null,
        };
        if(idCentre && centres[idCentre].idReg === idReg) {
            nextQuery[idCentre] = idCentre;
        }
        history.push(getUrl({
            query: nextQuery
        }));
    }
}
export const useChangeIdDep = () => {
    const history = useHistory();
    const getUrl = useRouter();
    const idCentre = useQuery(QUERY_PARAM_ID_ETAB, getDefaultParams()[QUERY_PARAM_ID_ETAB]);
    const centres = useSelector(state => state.staticData.centres);

    return (idHD, otherQuery = {}) => {
        idHD = !idHD ? null : parseInt(idHD, 10);
        const nextQuery = {
            ...otherQuery,
            [QUERY_PARAM_TYPE_GEO]: PARAM_TYPE_GEO_HD,
            [QUERY_PARAM_ID_HD]: idHD,
            [QUERY_PARAM_ID_REG]: null,
            [QUERY_PARAM_ID_ETAB]: null,
        };
        if(idCentre && centres[idCentre].idHD === idHD) {
            nextQuery[idCentre] = idCentre;
        }
        history.push(getUrl({
            query: nextQuery
        }));
    }
}

export const useChangeIdCentre = () => {
    const history = useHistory();
    const getUrl = useRouter();

    const typeGeo = useQuery(QUERY_PARAM_TYPE_GEO, getDefaultParams()[QUERY_PARAM_TYPE_GEO]);
    const centres = useSelector(state => state.staticData.centres);

    return (idCentre, otherQuery = {}) => {
        idCentre = !idCentre ? null : parseInt(idCentre, 10);
        const nextQuery = {
            ...otherQuery,
            [QUERY_PARAM_ID_ETAB]: idCentre,
            [QUERY_PARAM_ID_REG]: typeGeo === PARAM_TYPE_GEO_REG ? centres[idCentre].idReg : null,
            [QUERY_PARAM_ID_HD]: typeGeo === PARAM_TYPE_GEO_HD ? centres[idCentre].idHD : null,
        }

        history.push(getUrl({
            query: nextQuery
        }));
    }
}