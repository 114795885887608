const Checkbox = theme => ({
    root: {
        color: theme.palette.grey[200]
    },
    colorPrimary: {
        '&.Mui-disabled': {
            color: theme.palette.grey[100]
        }
    }
});

export default Checkbox;
