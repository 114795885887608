export const USER_PASSWORD_ERROR = 'USER_PASSWORD_ERROR';
export const USER_PASSWORD_PENDING = 'USER_PASSWORD_PENDING';
export const USER_PASSWORD_SUCCES = 'USER_PASSWORD_SUCCES';
export const USER_RESET_PASSWORD_ERROR = 'USER_RESET_PASSWORD_ERROR';
export const USER_RESET_PASSWORD_PENDING = 'USER_RESET_PASSWORD_PENDING';
export const USER_RESET_PASSWORD_SUCCES = 'USER_RESET_PASSWORD_SUCCES';

export const USER_SET = 'USER_SET';
export const USER_SAVE_ERROR = 'USER_SAVE_ERROR';
export const USER_SAVE_PENDING = 'USER_SAVE_PENDING';
export const USER_SAVE_SUCCES = 'USER_SAVE_SUCCES';

export const ROLE_CLIENT = 'ROLE_CLIENT';
export const ROLE_GRT_EXPERT = 'ROLE_GRT_EXPERT';
export const ROLE_ADMIN = 'ROLE_ADMIN';

export const CHANGE_PASSWORD = 'CHANGE_PASSWORD';

export const USER_SHARED_INFORMATIONS = 'sharedInformations';

export const OPEN_CI_REFERENCES = 'OPEN_CI_REFERENCES';
export const CLOSE_CI_REFERENCES = 'CLOSE_CI_REFERENCES';